export const navigations = [
    {
        name: 'Dashboard',
        path: '/dashboard',
        icon: 'dashboard',
    },
    {
        name: 'Inbox',
        path: '/inbox',
        icon: 'inbox',
    },
    {
        name: 'Pipeline',
        path: '/pipeline',
        icon: 'linear_scale',
    },

    {
        name: 'Jobs',
        path: '/jobs',
        icon: 'settings_input_component',
    },
    {
        name: 'Search',
        path: '/search',
        icon: 'search',
    },
]
