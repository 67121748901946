import React, { useEffect, useState } from 'react'
import {
    Avatar,
    Box,
    Breadcrumbs,
    Card,
    CardContent,
    FormControl,
    FormLabel,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Typography,
} from '@mui/material'
import { getData } from 'app/services/Api'
import DashboardIcon from '@mui/icons-material/Dashboard'
import LinearScaleIcon from '@mui/icons-material/LinearScale'
import SettingsInputComponentIcon from '@mui/icons-material/SettingsInputComponent'
import InboxIcon from '@mui/icons-material/Inbox'
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined'
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined'
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined'
import ReactEcharts from 'echarts-for-react'
import OpenInFullOutlinedIcon from '@mui/icons-material/OpenInFullOutlined'
import CloseFullscreenOutlinedIcon from '@mui/icons-material/CloseFullscreenOutlined'
import useNotification from 'app/hooks/useNotification'
import shortid from 'shortid'

const Reports = () => {
    const [pipelineData, setPipelineData] = useState(null)
    const [pipelineId, setPipelineId] = useState(-1)
    let [totalPageCount, setTotalPageCount] = useState(0)
    let [maxPageCount, setMaxPageCount] = useState(0)
    let [avgPageCount, setAvgPageCount] = useState(0)
    let [minPageCount, setMinPageCount] = useState(0)
    let [totalDocSize, setTotalDocSize] = useState(0)
    let [maxDocSize, setMaxDocSize] = useState(0)
    let [avgDocSize, setAvgDocSize] = useState(0)
    let [minDocSize, setMinDocSize] = useState(0)
    let [maxExecutionTime, setMaxExecutionTime] = useState(0)
    let [avgExecutionTime, setAvgExecutionTime] = useState(0)
    let [minExecutionTime, setMinExecutionTime] = useState(0)
    let [totalDocumentCount, setTotalDocumentCount] = useState(0)
    let [totalPipelineCount, setTotalPipelineCount] = useState(0)
    let [totalInboxCount, setTotalInboxCount] = useState(0)
    let [totalJobsCount, setTotalJobsCount] = useState(0)
    let [totalPDocumentCount, setTotalPDocumentCount] = useState(0)
    let [completed, setCompleted] = useState(0)
    let [validationFailed, setValidationFailed] = useState(0)
    let [failed, setFailed] = useState(0)
    let [pipelineStatsExpand, setPipelineStatsExpand] = useState(false)
    let [docStatsExpand, setDocStatsExpand] = useState(false)
    const { createNotification } = useNotification()

    useEffect(() => {
        getData('/core/pipeline').then((data) => {
            if (data !== null) {
                setPipelineData(data.content)
                setTotalPipelineCount(data.totalElements)
            }
        })
        getData('/core/inbox/documents').then((data) => {
            if (data !== null) {
                setTotalInboxCount(data.totalElements)
            }
        })
        getData('/core/dashboard/notification/verificationpendingdata').then(
            (data) => {
                if (data !== null) {
                    let count = 0
                    Object.keys(data).forEach((value) => {
                        count += data[value].length
                    })
                    if (
                        count > 0 &&
                        (sessionStorage.getItem('flag') === null ||
                            !sessionStorage.getItem('flag'))
                    ) {
                        Object.keys(data).forEach((value) => {
                            sessionStorage.setItem('flag', true)
                            createNotification({
                                id: shortid.generate(),
                                heading: 'Message',
                                icon: {
                                    name: 'chat',
                                    color: 'primary',
                                },
                                timestamp: new Date(),
                                title: count + ' Document Validations Failed.',
                                subtitle: '',
                                list: data,
                            })
                        })
                    }
                }
            }
        )
        getData('/core/job').then((data) => {
            if (data !== null) {
                setTotalJobsCount(data.totalElements)
            }
        })
        getData('/core/dashboard/report/document').then((data) => {
            if (data !== null) {
                setTotalPageCount(data['Total Page Count'])
                setMaxPageCount(data['Maximum Page Count in a Document'])
                setAvgPageCount(
                    parseInt(data['Average Page Count in a Document'])
                )
                setMinPageCount(data['Minimum Page Count in a Document'])
                setTotalDocSize(formatBytes(data['Total Document Size']))
                setMaxDocSize(formatBytes(data['Maximum Document Size']))
                setAvgDocSize(
                    formatBytes(parseInt(data['Average Document Size']))
                )
                setMinDocSize(formatBytes(data['Minimum Document Size']))
                setMaxExecutionTime(
                    formatSeconds(data['Maximum Execution Time'])
                )
                setAvgExecutionTime(
                    formatSeconds(parseInt(data['Average Execution Time']))
                )
                setMinExecutionTime(
                    formatSeconds(data['Minimum Execution Time'])
                )
                setTotalDocumentCount(data['Total Document Count'])
                setTotalPDocumentCount(data['Total Document Count'])
            }
        })

        getData('/core/dashboard/report/document/status').then((data) => {
            if (data !== null && data.length > 0) {
                data.forEach((val) => {
                    if (val['_id'] === 'COMPLETED') {
                        setCompleted(val['Count'])
                    }
                    if (val['_id'] === 'VALIDATION_FAILED') {
                        setValidationFailed(val['Count'])
                    }
                    if (val['_id'] === 'FAILED') {
                        setFailed(val['Count'])
                    }
                })
            }
        })
    }, [])

    const handleChange = (event) => {
        setPipelineId(event.target.value)
        if (event.target.value !== '-1') {
            getData(
                '/core/dashboard/report/document/' + event.target.value
            ).then((data) => {
                if (data !== null) {
                    setData(data)
                }
            })
            getData(
                '/core/dashboard/report/document/status/' + event.target.value
            ).then((data) => {
                if (data !== null && data.length > 0) {
                    data.forEach((val) => {
                        if (val['_id'] === 'COMPLETED') {
                            setCompleted(val['Count'])
                        }
                        if (val['_id'] === 'VALIDATION_FAILED') {
                            setValidationFailed(val['Count'])
                        }
                        if (val['_id'] === 'FAILED') {
                            setFailed(val['Count'])
                        }
                    })
                }
            })
            getData(
                '/core/dashboard/summary/' + event.target.value + '/data'
            ).then((data) => {
                console.log(data)
            })
            getData(
                '/core/dashboard/summary/' + event.target.value + '/header'
            ).then((data) => {
                console.log(data)
            })
        } else {
            getData('/core/dashboard/report/document').then((data) => {
                if (data !== null) {
                    setData(data)
                }
            })
        }
    }

    const setData = (data) => {
        setTotalPageCount(data['Total Page Count'])
        setMaxPageCount(data['Maximum Page Count in a Document'])
        setAvgPageCount(parseInt(data['Average Page Count in a Document']))
        setMinPageCount(data['Minimum Page Count in a Document'])
        setTotalDocSize(formatBytes(data['Total Document Size']))
        setMaxDocSize(formatBytes(data['Maximum Document Size']))
        setAvgDocSize(formatBytes(parseInt(data['Average Document Size'])))
        setMinDocSize(formatBytes(data['Minimum Document Size']))
        setMaxExecutionTime(formatSeconds(data['Maximum Execution Time']))
        setAvgExecutionTime(
            formatSeconds(parseInt(data['Average Execution Time']))
        )
        setMinExecutionTime(formatSeconds(data['Minimum Execution Time']))
        setTotalPDocumentCount(data['Total Document Count'])
    }

    const formatBytes = (bytes) => {
        if (bytes === 0) return '0 Bytes'

        const k = 1024
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

        const i = Math.floor(Math.log(bytes) / Math.log(k))

        return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i]
    }

    const formatSeconds = (milliseconds) => {
        if (milliseconds === 0) return '0 Seconds'

        const k = 1000
        const sizes = ['Milli Seconds', 'Seconds', 'Minutes', 'Hours']

        const i = Math.floor(Math.log(milliseconds) / Math.log(k))

        return (
            parseFloat((milliseconds / Math.pow(k, i)).toFixed(0)) +
            ' ' +
            sizes[i]
        )
    }

    const executionTime = {
        legend: {},
        tooltip: {},
        dataset: {
            source: [
                ['Status', 'Completed', 'Validation Failed', 'Failed'],
                ['Count', completed, validationFailed, failed],
            ],
        },
        xAxis: { type: 'category' },
        yAxis: {},
        series: [
            {
                type: 'bar',
                itemStyle: { normal: { color: 'rgba(145, 204, 117, 1)' } },
            },
            {
                type: 'bar',
                itemStyle: { normal: { color: 'rgba(255, 201, 10, 1)' } },
            },
            {
                type: 'bar',
                itemStyle: { normal: { color: 'rgba(232, 67, 63, 1)' } },
            },
        ],
    }

    return (
        <Box sx={{ ml: 2, mr: 2, mt: 2, mb: 2 }}>
            <Breadcrumbs aria-label="breadcrumb" sx={{ ml: 2, mb: 1 }}>
                <Typography
                    color="text.primary"
                    sx={{ display: 'flex', alignItems: 'center' }}
                >
                    <DashboardIcon />
                    Dashboard
                </Typography>
            </Breadcrumbs>
            <Card variant="outlined">
                <CardContent>
                    <Box>
                        <Grid container>
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    mt: 1,
                                    display:
                                        pipelineStatsExpand || docStatsExpand
                                            ? 'none'
                                            : 'block',
                                }}
                            >
                                <Card variant="outlined">
                                    <CardContent>
                                        <Box>
                                            <FormLabel>
                                                Quick Statistics
                                            </FormLabel>
                                        </Box>
                                        <Box sx={{ mt: 4 }}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={3}>
                                                    <Card variant="outlined">
                                                        <CardContent>
                                                            <Grid container>
                                                                <Grid
                                                                    item
                                                                    xs={6}
                                                                    sx={{
                                                                        alignItems:
                                                                            'center',
                                                                        justifyContent:
                                                                            'center',
                                                                        display:
                                                                            'flex',
                                                                    }}
                                                                >
                                                                    <Paper
                                                                        elevation={
                                                                            10
                                                                        }
                                                                        sx={{
                                                                            minWidth: 80,
                                                                            minHeight: 80,
                                                                            alignItems:
                                                                                'center',
                                                                            justifyContent:
                                                                                'center',
                                                                            display:
                                                                                'flex',
                                                                            position:
                                                                                'absolute',
                                                                            mt:
                                                                                '-8%',
                                                                            bgcolor:
                                                                                '#38c1c9',
                                                                            color:
                                                                                '#fff',
                                                                        }}
                                                                    >
                                                                        <LinearScaleIcon
                                                                            sx={{
                                                                                fontSize:
                                                                                    '36px',
                                                                            }}
                                                                        />
                                                                    </Paper>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={6}
                                                                    sx={{
                                                                        textAlign:
                                                                            'right',
                                                                    }}
                                                                >
                                                                    <Box
                                                                        sx={{
                                                                            fontSize:
                                                                                '50px',
                                                                        }}
                                                                    >
                                                                        {
                                                                            totalPipelineCount
                                                                        }
                                                                    </Box>
                                                                    <FormLabel>
                                                                        Pipelines
                                                                    </FormLabel>
                                                                </Grid>
                                                            </Grid>
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Card variant="outlined">
                                                        <CardContent>
                                                            <Grid container>
                                                                <Grid
                                                                    item
                                                                    xs={6}
                                                                    sx={{
                                                                        alignItems:
                                                                            'center',
                                                                        justifyContent:
                                                                            'center',
                                                                        display:
                                                                            'flex',
                                                                    }}
                                                                >
                                                                    <Paper
                                                                        elevation={
                                                                            10
                                                                        }
                                                                        sx={{
                                                                            minWidth: 80,
                                                                            minHeight: 80,
                                                                            alignItems:
                                                                                'center',
                                                                            justifyContent:
                                                                                'center',
                                                                            display:
                                                                                'flex',
                                                                            position:
                                                                                'absolute',
                                                                            mt:
                                                                                '-8%',
                                                                            bgcolor:
                                                                                '#ffc90a',
                                                                            color:
                                                                                '#fff',
                                                                        }}
                                                                    >
                                                                        <SettingsInputComponentIcon
                                                                            sx={{
                                                                                fontSize:
                                                                                    '36px',
                                                                            }}
                                                                        />
                                                                    </Paper>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={6}
                                                                    sx={{
                                                                        textAlign:
                                                                            'right',
                                                                    }}
                                                                >
                                                                    <Box
                                                                        sx={{
                                                                            fontSize:
                                                                                '50px',
                                                                        }}
                                                                    >
                                                                        {
                                                                            totalJobsCount
                                                                        }
                                                                    </Box>
                                                                    <FormLabel>
                                                                        Jobs
                                                                    </FormLabel>
                                                                </Grid>
                                                            </Grid>
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Card variant="outlined">
                                                        <CardContent>
                                                            <Grid container>
                                                                <Grid
                                                                    item
                                                                    xs={6}
                                                                    sx={{
                                                                        alignItems:
                                                                            'center',
                                                                        justifyContent:
                                                                            'center',
                                                                        display:
                                                                            'flex',
                                                                    }}
                                                                >
                                                                    <Paper
                                                                        elevation={
                                                                            10
                                                                        }
                                                                        sx={{
                                                                            minWidth: 80,
                                                                            minHeight: 80,
                                                                            alignItems:
                                                                                'center',
                                                                            justifyContent:
                                                                                'center',
                                                                            display:
                                                                                'flex',
                                                                            position:
                                                                                'absolute',
                                                                            mt:
                                                                                '-8%',
                                                                            bgcolor:
                                                                                '#1d4f70',
                                                                            color:
                                                                                '#fff',
                                                                        }}
                                                                    >
                                                                        <InboxIcon
                                                                            sx={{
                                                                                fontSize:
                                                                                    '36px',
                                                                            }}
                                                                        />
                                                                    </Paper>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={6}
                                                                    sx={{
                                                                        textAlign:
                                                                            'right',
                                                                    }}
                                                                >
                                                                    <Box
                                                                        sx={{
                                                                            fontSize:
                                                                                '50px',
                                                                        }}
                                                                    >
                                                                        {
                                                                            totalInboxCount
                                                                        }
                                                                    </Box>
                                                                    <FormLabel>
                                                                        Inbox
                                                                    </FormLabel>
                                                                </Grid>
                                                            </Grid>
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Card variant="outlined">
                                                        <CardContent>
                                                            <Grid container>
                                                                <Grid
                                                                    item
                                                                    xs={6}
                                                                    sx={{
                                                                        alignItems:
                                                                            'center',
                                                                        justifyContent:
                                                                            'center',
                                                                        display:
                                                                            'flex',
                                                                    }}
                                                                >
                                                                    <Paper
                                                                        elevation={
                                                                            10
                                                                        }
                                                                        sx={{
                                                                            minWidth: 80,
                                                                            minHeight: 80,
                                                                            alignItems:
                                                                                'center',
                                                                            justifyContent:
                                                                                'center',
                                                                            display:
                                                                                'flex',
                                                                            position:
                                                                                'absolute',
                                                                            mt:
                                                                                '-8%',
                                                                            bgcolor:
                                                                                '#e8433f',
                                                                            color:
                                                                                '#fff',
                                                                        }}
                                                                    >
                                                                        <FileCopyOutlinedIcon
                                                                            sx={{
                                                                                fontSize:
                                                                                    '36px',
                                                                            }}
                                                                        />
                                                                    </Paper>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={6}
                                                                    sx={{
                                                                        textAlign:
                                                                            'right',
                                                                    }}
                                                                >
                                                                    <Box
                                                                        sx={{
                                                                            fontSize:
                                                                                '50px',
                                                                        }}
                                                                    >
                                                                        {
                                                                            totalDocumentCount
                                                                        }
                                                                    </Box>
                                                                    <FormLabel>
                                                                        Documents
                                                                    </FormLabel>
                                                                </Grid>
                                                            </Grid>
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12}>
                                <Box
                                    sx={{
                                        float: 'right',
                                        minWidth: '25%',
                                        mt: 2,
                                    }}
                                >
                                    <Grid container>
                                        <Grid item xs={12} key={'pipelineID'}>
                                            <FormControl
                                                fullWidth
                                                size="small"
                                                key={'pipelineId'}
                                            >
                                                <InputLabel
                                                    id="pipelineId"
                                                    key={'pipelineIdLabel'}
                                                >
                                                    Pipeline
                                                </InputLabel>
                                                <Select
                                                    labelId="pipelineId"
                                                    id="pipelineId"
                                                    name="pipelineId"
                                                    key={'pipelineId'}
                                                    label="Pipeline"
                                                    value={pipelineId}
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem
                                                        selected
                                                        value={'-1'}
                                                        key={'pipeline-1'}
                                                    >
                                                        {'All'}
                                                    </MenuItem>
                                                    {pipelineData !== null &&
                                                        pipelineData.map(
                                                            (data, index) => (
                                                                <MenuItem
                                                                    value={
                                                                        data.id
                                                                    }
                                                                    key={
                                                                        'pipeline' +
                                                                        index
                                                                    }
                                                                >
                                                                    {data.name}
                                                                </MenuItem>
                                                            )
                                                        )}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sx={{ mt: 3 }}>
                                <Box>
                                    <Grid container spacing={2}>
                                        <Grid
                                            item
                                            xs={pipelineStatsExpand ? 12 : 8}
                                            sx={{
                                                display: docStatsExpand
                                                    ? 'none'
                                                    : 'block',
                                            }}
                                        >
                                            <Card variant="outlined">
                                                <CardContent>
                                                    <Grid container spacing={2}>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sx={{
                                                                marginTop:
                                                                    '-35px',
                                                                left: pipelineStatsExpand
                                                                    ? '47%'
                                                                    : '31%',
                                                                right: '65%',
                                                                position:
                                                                    'absolute',
                                                            }}
                                                        >
                                                            <Avatar
                                                                sx={{
                                                                    bgcolor:
                                                                        '#38c1c9',
                                                                }}
                                                            >
                                                                <QueryStatsOutlinedIcon />
                                                            </Avatar>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sx={{
                                                                textAlign:
                                                                    'center',
                                                                mb: 2,
                                                                mt: 1,
                                                            }}
                                                        >
                                                            <FormLabel>
                                                                Pipeline
                                                                Statistics
                                                            </FormLabel>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Box
                                                                sx={{
                                                                    float:
                                                                        'right',
                                                                    mt: -9,
                                                                    mr: -1,
                                                                }}
                                                            >
                                                                <IconButton
                                                                    onClick={() => {
                                                                        setPipelineStatsExpand(
                                                                            !pipelineStatsExpand
                                                                        )
                                                                    }}
                                                                >
                                                                    {!pipelineStatsExpand && (
                                                                        <OpenInFullOutlinedIcon
                                                                            sx={{
                                                                                color:
                                                                                    '#34314c4f',
                                                                                fontSize:
                                                                                    '1rem',
                                                                            }}
                                                                        />
                                                                    )}
                                                                    {pipelineStatsExpand && (
                                                                        <CloseFullscreenOutlinedIcon
                                                                            sx={{
                                                                                color:
                                                                                    '#34314c4f',
                                                                                fontSize:
                                                                                    '1rem',
                                                                            }}
                                                                        />
                                                                    )}
                                                                </IconButton>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Paper
                                                                elevation={5}
                                                            >
                                                                <Grid
                                                                    container
                                                                    sx={{
                                                                        textAlign:
                                                                            'center',
                                                                        pb: 1,
                                                                        m:
                                                                            '0px',
                                                                    }}
                                                                >
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                    >
                                                                        <h1>
                                                                            {
                                                                                totalPageCount
                                                                            }
                                                                        </h1>
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                    >
                                                                        Total
                                                                        Pages
                                                                    </Grid>
                                                                </Grid>
                                                            </Paper>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Paper
                                                                elevation={5}
                                                            >
                                                                <Grid
                                                                    container
                                                                    sx={{
                                                                        textAlign:
                                                                            'center',
                                                                        pb: 1,
                                                                        m:
                                                                            '0px',
                                                                    }}
                                                                >
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                    >
                                                                        <h1>
                                                                            {
                                                                                maxPageCount
                                                                            }
                                                                        </h1>
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                    >
                                                                        Max Page
                                                                        Count
                                                                    </Grid>
                                                                </Grid>
                                                            </Paper>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Paper
                                                                elevation={5}
                                                            >
                                                                <Grid
                                                                    container
                                                                    sx={{
                                                                        textAlign:
                                                                            'center',
                                                                        pb: 1,
                                                                        m:
                                                                            '0px',
                                                                    }}
                                                                >
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                    >
                                                                        <h1>
                                                                            {
                                                                                avgPageCount
                                                                            }
                                                                        </h1>
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                    >
                                                                        Avg Page
                                                                        Count
                                                                    </Grid>
                                                                </Grid>
                                                            </Paper>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Paper
                                                                elevation={5}
                                                            >
                                                                <Grid
                                                                    container
                                                                    sx={{
                                                                        textAlign:
                                                                            'center',
                                                                        pb: 1,
                                                                        m:
                                                                            '0px',
                                                                    }}
                                                                >
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                    >
                                                                        <h1>
                                                                            {
                                                                                minPageCount
                                                                            }
                                                                        </h1>
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                    >
                                                                        Min Page
                                                                        Count
                                                                    </Grid>
                                                                </Grid>
                                                            </Paper>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Paper
                                                                elevation={5}
                                                            >
                                                                <Grid
                                                                    container
                                                                    sx={{
                                                                        textAlign:
                                                                            'center',
                                                                        pb: 1,
                                                                        m:
                                                                            '0px',
                                                                    }}
                                                                >
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                    >
                                                                        <h1>
                                                                            {
                                                                                totalDocSize
                                                                            }
                                                                        </h1>
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                    >
                                                                        Total
                                                                        Document
                                                                        Size
                                                                    </Grid>
                                                                </Grid>
                                                            </Paper>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Paper
                                                                elevation={5}
                                                            >
                                                                <Grid
                                                                    container
                                                                    sx={{
                                                                        textAlign:
                                                                            'center',
                                                                        pb: 1,
                                                                        m:
                                                                            '0px',
                                                                    }}
                                                                >
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                    >
                                                                        <h1>
                                                                            {
                                                                                maxDocSize
                                                                            }
                                                                        </h1>
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                    >
                                                                        Max
                                                                        Document
                                                                        Size
                                                                    </Grid>
                                                                </Grid>
                                                            </Paper>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Paper
                                                                elevation={5}
                                                            >
                                                                <Grid
                                                                    container
                                                                    sx={{
                                                                        textAlign:
                                                                            'center',
                                                                        pb: 1,
                                                                        m:
                                                                            '0px',
                                                                    }}
                                                                >
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                    >
                                                                        <h1>
                                                                            {
                                                                                avgDocSize
                                                                            }
                                                                        </h1>
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                    >
                                                                        Avg
                                                                        Document
                                                                        Size
                                                                    </Grid>
                                                                </Grid>
                                                            </Paper>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Paper
                                                                elevation={5}
                                                            >
                                                                <Grid
                                                                    container
                                                                    sx={{
                                                                        textAlign:
                                                                            'center',
                                                                        pb: 1,
                                                                        m:
                                                                            '0px',
                                                                    }}
                                                                >
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                    >
                                                                        <h1>
                                                                            {
                                                                                minDocSize
                                                                            }
                                                                        </h1>
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                    >
                                                                        Min
                                                                        Document
                                                                        Size
                                                                    </Grid>
                                                                </Grid>
                                                            </Paper>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Paper
                                                                elevation={5}
                                                            >
                                                                <Grid
                                                                    container
                                                                    sx={{
                                                                        textAlign:
                                                                            'center',
                                                                        pb: 1,
                                                                        m:
                                                                            '0px',
                                                                    }}
                                                                >
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                    >
                                                                        <h1>
                                                                            {
                                                                                maxExecutionTime
                                                                            }
                                                                        </h1>
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                    >
                                                                        Max
                                                                        Execution
                                                                        Time
                                                                    </Grid>
                                                                </Grid>
                                                            </Paper>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Paper
                                                                elevation={5}
                                                            >
                                                                <Grid
                                                                    container
                                                                    sx={{
                                                                        textAlign:
                                                                            'center',
                                                                        pb: 1,
                                                                        m:
                                                                            '0px',
                                                                    }}
                                                                >
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                    >
                                                                        <h1>
                                                                            {
                                                                                avgExecutionTime
                                                                            }
                                                                        </h1>
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                    >
                                                                        Avg
                                                                        Execution
                                                                        Time
                                                                    </Grid>
                                                                </Grid>
                                                            </Paper>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Paper
                                                                elevation={5}
                                                            >
                                                                <Grid
                                                                    container
                                                                    sx={{
                                                                        textAlign:
                                                                            'center',
                                                                        pb: 1,
                                                                        m:
                                                                            '0px',
                                                                    }}
                                                                >
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                    >
                                                                        <h1>
                                                                            {
                                                                                minExecutionTime
                                                                            }
                                                                        </h1>
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                    >
                                                                        Min
                                                                        Execution
                                                                        Time
                                                                    </Grid>
                                                                </Grid>
                                                            </Paper>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Paper
                                                                elevation={5}
                                                            >
                                                                <Grid
                                                                    container
                                                                    sx={{
                                                                        textAlign:
                                                                            'center',
                                                                        pb: 1,
                                                                        m:
                                                                            '0px',
                                                                    }}
                                                                >
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                    >
                                                                        <h1>
                                                                            {
                                                                                totalPDocumentCount
                                                                            }
                                                                        </h1>
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                    >
                                                                        Document
                                                                        Count
                                                                    </Grid>
                                                                </Grid>
                                                            </Paper>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={docStatsExpand ? 12 : 4}
                                            sx={{
                                                display: pipelineStatsExpand
                                                    ? 'none'
                                                    : 'block',
                                            }}
                                        >
                                            <Card variant="outlined">
                                                <CardContent>
                                                    <Grid container spacing={2}>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sx={{
                                                                marginTop:
                                                                    '-35px',
                                                                left: docStatsExpand
                                                                    ? '47%'
                                                                    : '79%',
                                                                position:
                                                                    'absolute',
                                                            }}
                                                        >
                                                            <Avatar
                                                                sx={{
                                                                    bgcolor:
                                                                        '#1d4f70',
                                                                }}
                                                            >
                                                                <BarChartOutlinedIcon />
                                                            </Avatar>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sx={{
                                                                textAlign:
                                                                    'center',
                                                                mb: 2,
                                                                mt: 1,
                                                            }}
                                                        >
                                                            <FormLabel>
                                                                Document Status
                                                            </FormLabel>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Box
                                                                sx={{
                                                                    float:
                                                                        'right',
                                                                    mt: -9,
                                                                    mr: -1,
                                                                }}
                                                            >
                                                                <IconButton
                                                                    onClick={() => {
                                                                        setDocStatsExpand(
                                                                            !docStatsExpand
                                                                        )
                                                                    }}
                                                                >
                                                                    {!docStatsExpand && (
                                                                        <OpenInFullOutlinedIcon
                                                                            sx={{
                                                                                color:
                                                                                    '#34314c4f',
                                                                                fontSize:
                                                                                    '1rem',
                                                                            }}
                                                                        />
                                                                    )}
                                                                    {docStatsExpand && (
                                                                        <CloseFullscreenOutlinedIcon
                                                                            sx={{
                                                                                color:
                                                                                    '#34314c4f',
                                                                                fontSize:
                                                                                    '1rem',
                                                                            }}
                                                                        />
                                                                    )}
                                                                </IconButton>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Box sx={{ mt: 4 }}>
                                                                <ReactEcharts
                                                                    option={{
                                                                        ...executionTime,
                                                                    }}
                                                                />
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    )
}

export default Reports
