import {
    Box,
    Grid,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Card,
} from '@mui/material'
import React, { useState, useCallback, useEffect } from 'react'
import TableRowsOutlinedIcon from '@mui/icons-material/TableRowsOutlined'
import { getData } from '../../services/Api'

const TableData = (props) => {
    const docData = props.docData
    const [tableData, setTableData] = useState(null)

    const getTableData = useCallback(() => {
        setTableData(null)
        if (docData !== null && docData.status !== 'PENDING') {
            getData(
                '/core/pipeline/' +
                    docData.pipelineId +
                    '/document/' +
                    docData.id +
                    '/tables?pageNo=' +
                    (props.page + 1)
            ).then((data) => {
                if (data !== null && data.length > 0) {
                    setTableData(data)
                }
            })
        }
    }, [docData, props.page])

    useEffect(() => {
        getTableData()
    }, [getTableData])

    const gotoPoint = (boundingBox, pageNo) => {
        props.drawPoint(boundingBox, pageNo)
    }
    return (
        <Box
            sx={{
                m: 1,
                minWidth:
                    docData === null ||
                    tableData === null ||
                    tableData === undefined
                        ? 0
                        : 800,
                minHeight:
                    docData === null ||
                    tableData === null ||
                    tableData === undefined
                        ? 0
                        : 455,
            }}
        >
            <Grid container>
                {(docData === null ||
                    tableData === null ||
                    tableData === undefined) && (
                    <Grid
                        item
                        xs={12}
                        key={'noData'}
                        sx={{
                            textAlign: 'center',
                            pt: 'calc(455px /2 - 80px) !important',
                        }}
                    >
                        <TableRowsOutlinedIcon
                            sx={{ fontSize: 80, color: '#dcdcdc' }}
                        />
                        <Typography color={'#dcdcdc'}>
                            No Tables Available
                        </Typography>
                    </Grid>
                )}
                {docData !== null && (
                    <Grid item xs={12} key={'data'}>
                        <Box sx={{ mt: 2 }} key={'table'}>
                            {tableData !== null &&
                                tableData !== undefined &&
                                tableData.length > 0 &&
                                tableData.map((res, j) => (
                                    <Card
                                        sx={{ p: 2, mb: 1 }}
                                        key={'tableCard' + j}
                                    >
                                        <Table key={'table' + j}>
                                            <TableHead key={'tableHead' + j}>
                                                <TableRow key={'headRow' + j}>
                                                    {res.headers.map(
                                                        (tableData, k) => (
                                                            <TableCell
                                                                key={
                                                                    'headCell' +
                                                                    j +
                                                                    k
                                                                }
                                                            >
                                                                {tableData}
                                                            </TableCell>
                                                        )
                                                    )}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody key={'body' + j}>
                                                {res.rows.map(
                                                    (tableRows, k) => (
                                                        <TableRow
                                                            key={
                                                                'bodyRow' +
                                                                j +
                                                                k
                                                            }
                                                        >
                                                            {tableRows.cells.map(
                                                                (cell, l) => (
                                                                    <TableCell
                                                                        colSpan={cell.colSpan}
                                                                        key={
                                                                            'bodyCell' +
                                                                            j +
                                                                            k +
                                                                            l
                                                                        }
                                                                        onClick={() =>
                                                                            gotoPoint(
                                                                                cell.boundingBox,
                                                                                res.pageNo
                                                                            )
                                                                        }
                                                                    >
                                                                        {
                                                                            cell.text
                                                                        }
                                                                    </TableCell>
                                                                )
                                                            )}
                                                        </TableRow>
                                                    )
                                                )}
                                            </TableBody>
                                        </Table>
                                    </Card>
                                ))}
                        </Box>
                    </Grid>
                )}
            </Grid>
        </Box>
    )
}

export default TableData
