import React from 'react'
import Reports from './Reports'

const ReportsRoute = [
    {
        path: '/dashboard',
        element: <Reports />,
    },
]

export default ReportsRoute
