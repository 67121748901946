import React from 'react'
import { Typography, Button } from '@mui/material'
import { Box, styled } from '@mui/system'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { useNavigate } from 'react-router-dom'

const FlexBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
}))

const JustifyBox = styled(FlexBox)(() => ({
    maxWidth: 500,
    flexDirection: 'column',
    justifyContent: 'center',
}))

const NotFoundRoot = styled(FlexBox)(() => ({
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh !important',
}))

const NotFound = () => {
    const navigate = useNavigate()

    return (
        <NotFoundRoot>
            <JustifyBox>
                <ErrorOutlineIcon
                    sx={{ color: 'red', height: '150px', width: '150px' }}
                />
                <Typography>
                    You Don't have permission. Please contact with System Admin
                </Typography>
                <Button
                    color="primary"
                    variant="contained"
                    sx={{ textTransform: 'capitalize', mt: 5 }}
                    onClick={() => {
                        navigate('/pipeline')
                    }}
                >
                    {localStorage.getItem('name') === null ? 'Login' : 'Back'}
                </Button>
            </JustifyBox>
        </NotFoundRoot>
    )
}

export default NotFound
