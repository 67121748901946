import { Box, Grid, Typography } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import AbcOutlinedIcon from '@mui/icons-material/AbcOutlined'
import { getPlainText } from 'app/services/Api'

const TextData = (props) => {
    const docData = props.docData
    const [textData, setTextData] = useState(null)
    let [isPiiData, setPiiData] = useState(false)
    const getTextData = useCallback(() => {
        setTextData(null)
        checkPiiData()
        if (!isPiiData && docData !== null && docData.status !== 'PENDING') {
            getPlainText(
                '/core/pipeline/' +
                    docData.pipelineId +
                    '/document/' +
                    docData.id +
                    '/textcontent'
            ).then((data) => {
                if (data !== null && data.length > 0) {
                    setTextData(data)
                }
            })
        }
    }, [docData, props.page, props.editable])

    const checkPiiData = () => {
        if (fetchRoles() !== 'SysAdmin') {
            isPiiData = true
            setPiiData(isPiiData)
            return
        }
        if (
            props.pipelineData !== null &&
            props.pipelineData.hasOwnProperty('dataExtractionSteps')
        ) {
            if (
                props.pipelineData.dataExtractionSteps.hasOwnProperty(
                    'properties'
                )
            ) {
                props.pipelineData.dataExtractionSteps.properties.forEach(
                    (v) => {
                        if (v.key === 'piiDataRequired') {
                            setPiiData(v.value)
                        }
                    }
                )
            }
        }
    }

    const fetchRoles = () => {
        var payload = Buffer.from(
            localStorage.getItem('idToken').split('.')[1],
            'base64'
        )
        const roles = JSON.parse(payload.toString())['cognito:groups']
        return roles[0]
    }

    useEffect(() => {
        getTextData()
    }, [getTextData])
    return (
        <Box>
            <Grid container>
                {(docData === null || textData === null) && (
                    <Grid
                        item
                        xs={12}
                        key={'noData'}
                        sx={{
                            textAlign: 'center',
                            pt: 'calc(455px /2 - 80px) !important',
                        }}
                    >
                        <AbcOutlinedIcon
                            sx={{ fontSize: 80, color: '#dcdcdc' }}
                        />
                        <Typography color={'#dcdcdc'}>
                            No Text Available
                        </Typography>
                    </Grid>
                )}
                {docData !== null && textData !== null && (
                    <Box sx={{ p: 1, whiteSpace: 'pre-wrap' }}>{textData}</Box>
                )}
            </Grid>
        </Box>
    )
}

export default TextData
