import React, { useState, useEffect } from 'react'
import {
    Box,
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack,
    Tab,
    IconButton,
    Tabs,
    FormLabel,
    TextField,
    Typography,
    Chip,
    Slide,
    Button,
    Card,
    ToggleButtonGroup,
    ToggleButton,
    Menu,
    MenuList,
    MenuItem,
    Divider,
    Collapse,
} from '@mui/material'
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined'
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ZoomInIcon from '@mui/icons-material/ZoomIn'
import ZoomOutIcon from '@mui/icons-material/ZoomOut'
import Fullscreen from '@mui/icons-material/Fullscreen'
import FullscreenExitOutlinedIcon from '@mui/icons-material/FullscreenExitOutlined'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined'
import { getFileData, postData, deleteData, getData } from '../../services/Api'
import ListOutlinedIcon from '@mui/icons-material/ListOutlined'
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined'
import TableRowsOutlinedIcon from '@mui/icons-material/TableRowsOutlined'
import LanOutlinedIcon from '@mui/icons-material/LanOutlined'
import AbcOutlinedIcon from '@mui/icons-material/AbcOutlined'
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined'
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined'
import FormDatas from './FormData'
import EntitiesData from './EntitiesData'
import MedicalEntitiesData from './MedicalEntitiesData'
import TableData from './TableData'
import RegionData from './RegionData'
import SummaryData from './SummaryData'
import Moment from 'react-moment'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import GppBadOutlinedIcon from '@mui/icons-material/GppBadOutlined'
import RuleOutlinedIcon from '@mui/icons-material/RuleOutlined'
import LoopOutlinedIcon from '@mui/icons-material/LoopOutlined'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined'
import DeleteOutline from '@mui/icons-material/DeleteOutline'
import Alert from '../../components/Dialog/Alert'
import CachedIcon from '@mui/icons-material/Cached'
import SentimentNeutralOutlinedIcon from '@mui/icons-material/SentimentNeutralOutlined'
import SentimentData from './SentimentData'
import UploadFile from './UploadFile'
import TextData from './TextData'
import { ExpandLess, ExpandMore } from '@mui/icons-material'

const ListViewer = (props) => {
    const documentList = props.docList.content || []
    let [documentSelected, setDocumentSelected] = useState(-1)
    const [tabSelected, setTabSelected] = useState(0)
    const [viewerFull, setViewerFull] = useState(false)
    const [tabsFull, setTabsFull] = useState(false)
    const [listVisible, setListVisible] = useState(false)
    let [docSize, setDocSize] = useState(100)
    const [imageObj, setImageObj] = useState()
    let [currentPage, setCurrentPage] = useState(0)
    let [currentListPage, setCurrentListPage] = useState(0)
    let [currentPageTextBox, setCurrentPageTextBox] = useState(currentPage + 1)
    let [currentListPageTextBox, setCurrentListPageTextBox] = useState(
        currentListPage + 1
    )
    let [invalidFormData, setInvalidFormData] = useState()
    let [menuDrawer, setMenuDrawer] = useState(false)
    let [pipelineName, setPipelineName] = useState(null)
    let [documentName, setDocumentName] = useState(null)
    let [fromDate, setFromDate] = useState(null)
    let [toDate, setToDate] = useState(null)
    let [docStatus, setDocStatus] = useState(null)
    let [downloadMenu, setDownloadMenu] = useState(null)
    let [startValidation, setStartValidation] = useState(false)
    const [openAlertValue, setAlertValue] = useState(false)
    let [deleteDocId, setDeleteDocId] = useState(null)
    let [deletePipelineId, setDeletePipelineId] = useState(null)
    let [yesButtonStatus, setButtonStatus] = useState(false)
    let [lastVisitBoundingBox, setLastVisitBoundingBox] = useState(null)
    let [piiDataList, setPiiDataList] = useState(null)
    const [subMenuOpenForms, setSubMenuOpenForms] = useState(false)
    const [subMenuOpenTables, setSubMenuOpenTables] = useState(false)
    const [subMenuOpenEntities, setSubMenuOpenEntities] = useState(false)
    const [subMenuOpenSummary, setSubMenuOpenSummary] = useState(false)
    const [subMenuOpenSentiments, setSubMenuOpenSentiments] = useState(false)
    const openDownloadMenu = Boolean(downloadMenu)
    const handleClickDownloadMenu = (event) => {
        setDownloadMenu(event.currentTarget)
    }
    const closeDownloadMenu = () => {
        setDownloadMenu(null)
    }

    const tabChange = (event, newValue) => {
        setTabSelected(newValue)
    }

    const getPiiDataBoundingBox = (val) => {
        let boundingBox = []
        setPiiDataList(null)
        if (val.hasOwnProperty('pages') && val.pages !== null) {
            val.pages.forEach((v1, index) => {
                if (
                    index === currentPage &&
                    v1.hasOwnProperty('forms') &&
                    v1.forms !== null &&
                    v1.forms.length > 0
                ) {
                    v1.forms.forEach((f) => {
                        if (f.piiData !== null) {
                            boundingBox.push(f.boundingBox)
                        }
                    })
                }
            })
        }
        setPiiDataList(boundingBox)
        return boundingBox
    }

    const drawRectPiiData = (boundingBox) => {
        if (boundingBox.length > 0) {
            let canvas = document.getElementById('viewerCanvas')
            let ctx = canvas.getContext('2d')
            if (canvas) {
                boundingBox.forEach((b) => {
                    if (b !== null) {
                        let x1 = b.x1
                        let y1 = b.y1
                        let x2 = b.x2
                        let y2 = b.y2
                        let boxWidth = x2 - x1
                        let boxHeight = y2 - y1
                        ctx.fillStyle = '#cdcdcd'
                        ctx.fillRect(x1, y1, boxWidth, boxHeight)
                    }
                })
            }
        }
    }


    const [dataExtractionStepName, setDataExtractionStepName] = useState(props.dataExtractionStepName);
    useEffect(() => {
        setDataExtractionStepName(props.dataExtractionStepName);
      }, [props.dataExtractionStepName]);

    const getDocuments = (val, index) => {
        docSize = 100
        setDocumentSelected(index)
        let bBox = getPiiDataBoundingBox(val)
        const imgObj = new Image()
        props.setLoader(true)
        getFileData(
            '/core/pipeline/' +
                val.pipelineId +
                '/document/' +
                val.id +
                '/renderPage?pageNo=' +
                currentPage +
                '&dpi=96'
        ).then((data) => {
            if (data !== null) {
                props.setLoader(false)
                var objectURL = URL.createObjectURL(data)
                imgObj.src = objectURL
                imgObj.onload = () => {
                    drawImageWithSize(docSize, imgObj)
                    setImageObj(imgObj)
                    if (
                        localStorage.getItem('accessToken') !== null &&
                        (fetchRoles().includes('Reviewer') ||
                            fetchRoles().includes('PipelineAdmin')) &&
                        bBox.length > 0
                    ) {
                        drawRectPiiData(bBox)
                    }
                }
            }
        })
    }

    const getDocumentsForValidation = (val, index, boundingBox) => {
        docSize = 100
        setDocumentSelected(index)
        const imgObj = new Image()
        props.setLoader(true)
        getFileData(
            '/core/pipeline/' +
                val.pipelineId +
                '/document/' +
                val.id +
                '/renderPage?pageNo=' +
                currentPage +
                '&dpi=96'
        ).then((data) => {
            props.setLoader(false)
            if (data !== null) {
                var objectURL = URL.createObjectURL(data)
                imgObj.src = objectURL
                imgObj.onload = () => {
                    drawImageWithSize(docSize, imgObj)
                    setImageObj(imgObj)
                    if (
                        localStorage.getItem('accessToken') !== null &&
                        (fetchRoles().includes('Reviewer') ||
                            fetchRoles().includes('PipelineAdmin')) &&
                        piiDataList.length > 0
                    ) {
                        drawRectPiiData(piiDataList)
                    }
                }
                setTimeout(() => {
                    if (boundingBox !== null) {
                        let canvas = document.getElementById('viewerCanvas')
                        let viewer = document.getElementById('viewer')
                        if (canvas) {
                            let x1 = boundingBox.x1
                            let y1 = boundingBox.y1
                            let x2 = boundingBox.x2
                            let y2 = boundingBox.y2
                            let boxWidth = x2 - x1
                            let boxHeight = y2 - y1
                            let ctx = canvas.getContext('2d')
                            if (
                                localStorage.getItem('accessToken') !== null &&
                                (fetchRoles().includes('Reviewer') ||
                                    fetchRoles().includes('PipelineAdmin'))
                            ) {
                                clearRectangle(ctx)
                            } else {
                                ctx.clearRect(0, 0, canvas.width, canvas.height)
                                drawImageWithSize(docSize, imgObj)
                            }
                            ctx.beginPath()
                            ctx.lineWidth = '1'
                            ctx.strokeStyle = 'red'
                            ctx.rect(x1, y1, boxWidth, boxHeight)
                            ctx.stroke()
                            viewer.scrollTo({
                                top: y1 - 20,
                                left: x1 - 20,
                                behavior: 'smooth',
                            })
                        }
                    }
                }, 100)
            }
        })
    }

    const drawImageWithSize = function (size, imgObj) {
        let canvas = document.getElementById('viewerCanvas')
        const sizeDigit = Number(size)
        if (canvas) {
            let context = canvas.getContext('2d')
            let width = imgObj.width
            let height = imgObj.height
            if (sizeDigit !== 100) {
                width = (imgObj.width * sizeDigit) / 100
                height = imgObj.height * (width / imgObj.width)
            }
            context.clearRect(0, 0, canvas.width, canvas.height)
            canvas.width = width
            canvas.height = height
            context.drawImage(imgObj, 0, 0, width, height)
        }
    }

    const fetchRoles = () => {
        var payload = Buffer.from(
            localStorage.getItem('idToken').split('.')[1],
            'base64'
        )
        const roles = JSON.parse(payload.toString())['cognito:groups']
        return roles[0];
    }

    const drawRect = (boundingBox, pageNo) => {
        if (pageNo - 1 !== currentPage) {
            currentPage = pageNo - 1
            setCurrentPage(currentPage)
            setCurrentPageTextBox(pageNo)
            getDocumentsForValidation(
                documentList[documentSelected],
                documentSelected,
                boundingBox
            )
            return
        }
        if (boundingBox !== null) {
            let canvas = document.getElementById('viewerCanvas')
            let viewer = document.getElementById('viewer')
            if (canvas) {
                let x1 = boundingBox.x1
                let y1 = boundingBox.y1
                let x2 = boundingBox.x2
                let y2 = boundingBox.y2
                let boxWidth = x2 - x1
                let boxHeight = y2 - y1
                let ctx = canvas.getContext('2d')
                if (
                    localStorage.getItem('accessToken') !== null &&
                    (fetchRoles().includes('Reviewer') ||
                        fetchRoles().includes('PipelineAdmin'))
                ) {
                    clearRectangle(ctx)
                } else {
                    ctx.clearRect(0, 0, canvas.width, canvas.height)
                    drawImageWithSize(docSize, imageObj)
                }
                ctx.beginPath()
                ctx.lineWidth = '1'
                ctx.strokeStyle = 'red'
                ctx.rect(x1, y1, boxWidth, boxHeight)
                ctx.stroke()
                viewer.scrollTo({
                    top: y1 - 20,
                    left: x1 - 20,
                    behavior: 'smooth',
                })
                setLastVisitBoundingBox(boundingBox)
            }
        }
    }

    const clearRectangle = (ctx) => {
        if (lastVisitBoundingBox !== null) {
            if (piiDataList.length > 0) {
                piiDataList.forEach((bound) => {
                    if (
                        bound.x1 === lastVisitBoundingBox.x1 &&
                        bound.x2 === lastVisitBoundingBox.x2 &&
                        bound.y1 === lastVisitBoundingBox.y1 &&
                        bound.y2 === lastVisitBoundingBox.y2
                    ) {
                        ctx.clearRect(
                            lastVisitBoundingBox.x1 - 1,
                            lastVisitBoundingBox.y1 - 1,
                            lastVisitBoundingBox.x2 +
                                1 -
                                lastVisitBoundingBox.x1 +
                                1,
                            lastVisitBoundingBox.y2 +
                                1 -
                                lastVisitBoundingBox.y1 +
                                1
                        )
                        ctx.fillStyle = '#cdcdcd'
                        ctx.fillRect(
                            lastVisitBoundingBox.x1,
                            lastVisitBoundingBox.y1,
                            lastVisitBoundingBox.x2 - lastVisitBoundingBox.x1,
                            lastVisitBoundingBox.y2 - lastVisitBoundingBox.y1
                        )
                    }
                })
            }
        }
    }

    const downloadDoc = (type, name) => {
        props.setLoader(true)
        let val = documentList[documentSelected]
        getFileData(
            '/core/pipeline/' +
                val.pipelineId +
                '/document/' +
                val.id +
                '/' +
                type
        ).then((data) => {
            props.setLoader(false)
            var objectURL = URL.createObjectURL(data)
            const link = document.createElement('a')
            link.href = objectURL
            if (type !== 'content') {
                link.setAttribute(
                    'download',
                    val.name.split('.')[0] + '_' + name + '.xlsx'
                )
            } else {
                link.setAttribute('download', val.name)
            }
            document.body.appendChild(link)
            link.click()
            link.parentNode.removeChild(link)
        })
    }

    const downloadJson = (type, name) => {
        props.setLoader(true)
        let val = documentList[documentSelected]
        let url = ''
        if (type === 'forms' || type === 'tables') {
            url +=
                '/core/pipeline/' +
                val.pipelineId +
                '/document/' +
                val.id +
                '/' +
                type +
                '?pageNo=-1'
        } else {
            url +=
                '/core/pipeline/' +
                val.pipelineId +
                '/document/' +
                val.id +
                '/' +
                type
        }
        getData(url).then((data) => {
            props.setLoader(false)
            var objectURL =
                'data:text/json;charset=utf-8,' +
                encodeURIComponent(JSON.stringify(data))
            const link = document.createElement('a')
            link.href = objectURL
            link.setAttribute(
                'download',
                val.name.split('.')[0] + '_' + name + '.json'
            )
            document.body.appendChild(link)
            link.click()
            link.parentNode.removeChild(link)
        })
    }

    const submitData = () => {
        props.setLoader(true)
        let val = documentList[documentSelected]
        postData(
            '/core/pipeline/' +
                val.pipelineId +
                '/document/' +
                val.id +
                '/resubmit',
            invalidFormData
        ).then((response) => {
            props.setLoader(false)
            props.uploadedData({
                severity: 'success',
                message: 'Successfully submitted for Re-validation.',
                open: true,
                link: {
                    pipelineId: val.pipelineId,
                    pipelineName: val.pipeline.name,
                },
            })
            resetDocList()
        })
    }

    const resetDocList = () => {
        setDocumentSelected(-1)
        setCurrentListPage(0)
        setCurrentListPageTextBox(0 + 1)
        props.updatePage(0)
        props.reloadDoc()
        setStartValidation(false)
    }

    const getFormData = (data) => {
        setInvalidFormData(data)
    }

    const submitFilter = () => {
        const data = new Map()
        data.set('pipelineName', pipelineName)
        data.set('documentName', documentName)
        data.set('fromDate', fromDate)
        data.set('toDate', toDate)
        data.set('docStatus', docStatus)
        props.updateFilterData(Object.fromEntries(data))
    }

    const handleDocStatus = (val) => {
        docStatus = val
        setDocStatus(docStatus)
    }

    const deleteDocument = () => {
        if (deleteDocId !== null && deletePipelineId !== null) {
            props.setLoader(true)
            deleteData(
                '/core/pipeline/' +
                    deletePipelineId +
                    '/document/' +
                    deleteDocId
            ).then((response) => {
                props.setLoader(false)
                setDeleteDocId(null)
                setDeletePipelineId(null)
                props.uploadedData({
                    severity: 'success',
                    message: 'File Deleted Successfully.',
                    open: true,
                })
                resetDocList()
            })
        }
    }

    const setAlertDialog = (value) => {
        setAlertValue(value)
    }

    const setYesButtonStatus = (value) => {
        setButtonStatus(value)
        deleteDocument()
    }

    useEffect(() => {
        if (yesButtonStatus) {
            setButtonStatus(false)
        }
    }, [yesButtonStatus])

    const updateLoader = (val) => {
        props.setLoader(val)
    }

    const getUploadedData = (val) => {
        props.uploadedData({
            severity: val.severity,
            message: val.message,
            open: val.open,
        })
    }

    return (
        <Box sx={{ maxHeight: 493 }}>
            <Grid container>
                {!(listVisible || viewerFull || tabsFull) && (
                    <Grid item xs={2.5}>
                        <Stack direction="row" justifyContent="flex-start">
                            <IconButton
                                title="Filter"
                                onClick={() => {
                                    setMenuDrawer(!menuDrawer)
                                }}
                            >
                                <FilterAltOutlinedIcon />
                            </IconButton>
                            {documentList.length > 0 && (
                                <div>
                                    <IconButton
                                        disabled={currentListPage === 0}
                                        title="Back"
                                        onClick={() => {
                                            if (currentListPage === 0) {
                                                return false
                                            } else {
                                                setCurrentListPage(
                                                    --currentListPage
                                                )
                                                setCurrentListPageTextBox(
                                                    currentListPage + 1
                                                )
                                                props.updatePage(
                                                    currentListPage
                                                )
                                                setDocumentSelected(-1)
                                            }
                                        }}
                                    >
                                        <ArrowBackIcon />
                                    </IconButton>
                                    <TextField
                                        value={currentListPageTextBox}
                                        type="number"
                                        onChange={(event) => {
                                            setCurrentListPageTextBox(
                                                event.target.value
                                            )
                                            if (
                                                event.target.value >= 1 &&
                                                event.target.value <=
                                                    props.docList.totalPages
                                            ) {
                                                currentListPage =
                                                    event.target.value - 1
                                                setCurrentListPage(
                                                    currentListPage
                                                )
                                                props.updatePage(
                                                    currentListPage
                                                )
                                                setDocumentSelected(-1)
                                            }
                                        }}
                                        id="listPage"
                                        size="small"
                                        sx={{
                                            mt: '5px',
                                            width: '30px',
                                            '& .MuiOutlinedInput-root': {
                                                height: '30px',
                                            },
                                            '& .MuiOutlinedInput-input': {
                                                p: '7px',
                                            },
                                        }}
                                    />
                                    <FormLabel sx={{ mt: '11px' }}>
                                        /{props.docList.totalPages || 1}
                                    </FormLabel>
                                    <IconButton
                                        title="Forward"
                                        onClick={() => {
                                            if (
                                                props.docList.totalPages - 1 ===
                                                currentListPage
                                            ) {
                                                return false
                                            } else {
                                                setCurrentListPage(
                                                    ++currentListPage
                                                )
                                                setCurrentListPageTextBox(
                                                    currentListPage + 1
                                                )
                                                props.updatePage(
                                                    currentListPage
                                                )
                                                setDocumentSelected(-1)
                                            }
                                        }}
                                        disabled={
                                            currentListPage ===
                                            props.docList.totalPages - 1
                                        }
                                    >
                                        <ArrowForwardIcon />
                                    </IconButton>
                                </div>
                            )}
                            <IconButton
                                title="Refresh"
                                onClick={() => {
                                    resetDocList()
                                }}
                            >
                                <ReplayOutlinedIcon />
                            </IconButton>
                            {!(listVisible || viewerFull || tabsFull) && (
                                <IconButton
                                    title="Close List"
                                    onClick={() => setListVisible(true)}
                                >
                                    <ClearOutlinedIcon />
                                </IconButton>
                            )}
                        </Stack>
                    </Grid>
                )}
                {!tabsFull && (
                    <Grid item xs={viewerFull ? 12 : !listVisible ? 5.5 : 6}>
                        <Box>
                            <Stack direction="row" justifyContent="flex-start">
                                {listVisible && !viewerFull && (
                                    <IconButton
                                        title="List Menu"
                                        onClick={() => setListVisible(false)}
                                    >
                                        <ListOutlinedIcon />
                                    </IconButton>
                                )}
                                {documentSelected !== -1 && (
                                    <Stack
                                        direction="row"
                                        justifyContent="center"
                                        sx={{ ml: viewerFull ? '40%' : '25%' }}
                                    >
                                        <IconButton
                                            disabled = {
                                                piiDataList !== null && piiDataList.length > 0
                                            }

                                            title="Zoom Out"
                                            onClick={() => {
                                                if (docSize <= 10) {
                                                    return false
                                                }
                                                setDocSize(docSize - 10)
                                                drawImageWithSize(
                                                    docSize - 10,
                                                    imageObj
                                                )
                                            }}
                                        >
                                            <ZoomOutIcon />
                                        </IconButton>
                                        <IconButton
                                            disabled = {
                                                piiDataList !== null && piiDataList.length > 0
                                            }
                                            title="Zoom In"
                                            onClick={() => {
                                                if (docSize >= 200) {
                                                    return false
                                                }
                                                setDocSize(docSize + 10)
                                                drawImageWithSize(
                                                    docSize + 10,
                                                    imageObj
                                                )
                                            }}
                                        >
                                            <ZoomInIcon />
                                        </IconButton>
                                        <IconButton
                                            disabled={
                                                localStorage.getItem(
                                                    'accessToken'
                                                ) !== null &&
                                                (fetchRoles().includes(
                                                    'Reviewer'
                                                ) ||
                                                    fetchRoles().includes(
                                                        'PipelineAdmin'
                                                    ))
                                            }
                                            title="Download"
                                            onClick={handleClickDownloadMenu}
                                        >
                                            <CloudDownloadIcon />
                                        </IconButton>
                                        <Menu
                                            id="Download-menu"
                                            anchorEl={downloadMenu}
                                            open={openDownloadMenu}
                                            onClose={closeDownloadMenu}
                                        >
                                            <MenuList dense>
                                                <MenuItem
                                                    onClick={() => {
                                                        downloadDoc(
                                                            'content',
                                                            ''
                                                        )
                                                        closeDownloadMenu()
                                                    }}
                                                >
                                                    <ListItemIcon>
                                                        <InsertDriveFileOutlinedIcon />
                                                    </ListItemIcon>
                                                    <ListItemText>
                                                        Full Document
                                                    </ListItemText>
                                                </MenuItem>
                                                <MenuItem
                                                    onClick={() => {
                                                        setSubMenuOpenForms(
                                                            !subMenuOpenForms
                                                        )
                                                        setSubMenuOpenTables(
                                                            false
                                                        )
                                                        setSubMenuOpenEntities(
                                                            false
                                                        )
                                                        setSubMenuOpenSummary(
                                                            false
                                                        )
                                                        setSubMenuOpenSentiments(
                                                            false
                                                        )
                                                    }}
                                                >
                                                    <ListItemIcon>
                                                        <ArticleOutlinedIcon />
                                                    </ListItemIcon>
                                                    <ListItemText>
                                                        Forms
                                                    </ListItemText>
                                                    {subMenuOpenForms ? (
                                                        <ExpandLess />
                                                    ) : (
                                                        <ExpandMore />
                                                    )}
                                                </MenuItem>
                                                <Collapse
                                                    in={subMenuOpenForms}
                                                    timeout="auto"
                                                    unmountOnExit
                                                >
                                                    <MenuItem
                                                        onClick={() => {
                                                            downloadDoc(
                                                                'downloadFormsData',
                                                                'Forms'
                                                            )
                                                            closeDownloadMenu()
                                                        }}
                                                    >
                                                        <ListItemText
                                                            sx={{ pl: 6 }}
                                                        >
                                                            Excel
                                                        </ListItemText>
                                                    </MenuItem>
                                                    <MenuItem
                                                        onClick={() => {
                                                            downloadJson(
                                                                'forms',
                                                                'Forms'
                                                            )
                                                            closeDownloadMenu()
                                                        }}
                                                    >
                                                        <ListItemText
                                                            sx={{ pl: 6 }}
                                                        >
                                                            JSON
                                                        </ListItemText>
                                                    </MenuItem>
                                                </Collapse>
                                                <MenuItem
                                                    onClick={() => {
                                                        setSubMenuOpenForms(
                                                            false
                                                        )
                                                        setSubMenuOpenTables(
                                                            !subMenuOpenTables
                                                        )
                                                        setSubMenuOpenEntities(
                                                            false
                                                        )
                                                        setSubMenuOpenSummary(
                                                            false
                                                        )
                                                        setSubMenuOpenSentiments(
                                                            false
                                                        )
                                                    }}
                                                >
                                                    <ListItemIcon>
                                                        <TableRowsOutlinedIcon />
                                                    </ListItemIcon>
                                                    <ListItemText>
                                                        Tables
                                                    </ListItemText>
                                                    {subMenuOpenTables ? (
                                                        <ExpandLess />
                                                    ) : (
                                                        <ExpandMore />
                                                    )}
                                                </MenuItem>
                                                <Collapse
                                                    in={subMenuOpenTables}
                                                    timeout="auto"
                                                    unmountOnExit
                                                >
                                                    <MenuItem
                                                        onClick={() => {
                                                            downloadDoc(
                                                                'downloadTablesData',
                                                                'Tables'
                                                            )
                                                            closeDownloadMenu()
                                                        }}
                                                    >
                                                        <ListItemText
                                                            sx={{ pl: 6 }}
                                                        >
                                                            Excel
                                                        </ListItemText>
                                                    </MenuItem>
                                                    <MenuItem
                                                        onClick={() => {
                                                            downloadJson(
                                                                'tables',
                                                                'Tables'
                                                            )
                                                            closeDownloadMenu()
                                                        }}
                                                    >
                                                        <ListItemText
                                                            sx={{ pl: 6 }}
                                                        >
                                                            JSON
                                                        </ListItemText>
                                                    </MenuItem>
                                                </Collapse>
                                                <MenuItem
                                                    onClick={() => {
                                                        setSubMenuOpenForms(
                                                            false
                                                        )
                                                        setSubMenuOpenTables(
                                                            false
                                                        )
                                                        setSubMenuOpenEntities(
                                                            !subMenuOpenEntities
                                                        )
                                                        setSubMenuOpenSummary(
                                                            false
                                                        )
                                                        setSubMenuOpenSentiments(
                                                            false
                                                        )
                                                    }}
                                                >
                                                    <ListItemIcon>
                                                        <LanOutlinedIcon />
                                                    </ListItemIcon>
                                                    <ListItemText>
                                                        Entities
                                                    </ListItemText>
                                                    {subMenuOpenEntities ? (
                                                        <ExpandLess />
                                                    ) : (
                                                        <ExpandMore />
                                                    )}
                                                </MenuItem>
                                                <Collapse
                                                    in={subMenuOpenEntities}
                                                    timeout="auto"
                                                    unmountOnExit
                                                >
                                                    <MenuItem
                                                        onClick={() => {
                                                            downloadDoc(
                                                                'downloadEntitiesData',
                                                                'Entities'
                                                            )
                                                            closeDownloadMenu()
                                                        }}
                                                    >
                                                        <ListItemText
                                                            sx={{ pl: 6 }}
                                                        >
                                                            Excel
                                                        </ListItemText>
                                                    </MenuItem>
                                                    <MenuItem
                                                        onClick={() => {
                                                            downloadJson(
                                                                'entities',
                                                                'Entities'
                                                            )
                                                            closeDownloadMenu()
                                                        }}
                                                    >
                                                        <ListItemText
                                                            sx={{ pl: 6 }}
                                                        >
                                                            JSON
                                                        </ListItemText>
                                                    </MenuItem>
                                                </Collapse>
                                                <MenuItem
                                                    onClick={() => {
                                                        setSubMenuOpenForms(
                                                            false
                                                        )
                                                        setSubMenuOpenTables(
                                                            false
                                                        )
                                                        setSubMenuOpenEntities(
                                                            false
                                                        )
                                                        setSubMenuOpenSummary(
                                                            !subMenuOpenSummary
                                                        )
                                                        setSubMenuOpenSentiments(
                                                            false
                                                        )
                                                    }}
                                                >
                                                    <ListItemIcon>
                                                        <FactCheckOutlinedIcon />
                                                    </ListItemIcon>
                                                    <ListItemText>
                                                        Summary
                                                    </ListItemText>
                                                    {subMenuOpenSummary ? (
                                                        <ExpandLess />
                                                    ) : (
                                                        <ExpandMore />
                                                    )}
                                                </MenuItem>
                                                <Collapse
                                                    in={subMenuOpenSummary}
                                                    timeout="auto"
                                                    unmountOnExit
                                                >
                                                    <MenuItem
                                                        onClick={() => {
                                                            downloadDoc(
                                                                'downloadSummaryData',
                                                                'Summary'
                                                            )
                                                            closeDownloadMenu()
                                                        }}
                                                    >
                                                        <ListItemText
                                                            sx={{ pl: 6 }}
                                                        >
                                                            Excel
                                                        </ListItemText>
                                                    </MenuItem>
                                                    <MenuItem
                                                        onClick={() => {
                                                            downloadJson(
                                                                'summary',
                                                                'Summary'
                                                            )
                                                            closeDownloadMenu()
                                                        }}
                                                    >
                                                        <ListItemText
                                                            sx={{ pl: 6 }}
                                                        >
                                                            JSON
                                                        </ListItemText>
                                                    </MenuItem>
                                                </Collapse>
                                                <MenuItem
                                                    onClick={() => {
                                                        setSubMenuOpenForms(
                                                            false
                                                        )
                                                        setSubMenuOpenTables(
                                                            false
                                                        )
                                                        setSubMenuOpenEntities(
                                                            false
                                                        )
                                                        setSubMenuOpenSummary(
                                                            false
                                                        )
                                                        setSubMenuOpenSentiments(
                                                            !subMenuOpenSentiments
                                                        )
                                                    }}
                                                >
                                                    <ListItemIcon>
                                                        <SentimentNeutralOutlinedIcon />
                                                    </ListItemIcon>
                                                    <ListItemText>
                                                        Sentiments
                                                    </ListItemText>
                                                    {subMenuOpenSentiments ? (
                                                        <ExpandLess />
                                                    ) : (
                                                        <ExpandMore />
                                                    )}
                                                </MenuItem>
                                                <Collapse
                                                    in={subMenuOpenSentiments}
                                                    timeout="auto"
                                                    unmountOnExit
                                                >
                                                    <MenuItem
                                                        onClick={() => {
                                                            downloadDoc(
                                                                'downloadSentimentsData',
                                                                'Sentiments'
                                                            )
                                                            closeDownloadMenu()
                                                        }}
                                                    >
                                                        <ListItemText
                                                            sx={{ pl: 6 }}
                                                        >
                                                            Excel
                                                        </ListItemText>
                                                    </MenuItem>
                                                    <MenuItem
                                                        onClick={() => {
                                                            downloadJson(
                                                                'sentiments',
                                                                'Sentiments'
                                                            )
                                                            closeDownloadMenu()
                                                        }}
                                                    >
                                                        <ListItemText
                                                            sx={{ pl: 6 }}
                                                        >
                                                            JSON
                                                        </ListItemText>
                                                    </MenuItem>
                                                </Collapse>
                                            </MenuList>
                                        </Menu>
                                        <IconButton
                                            title="Previous Page"
                                            disabled={currentPage === 0}
                                            onClick={() => {
                                                if (currentPage === 0) {
                                                    return false
                                                } else {
                                                    setStartValidation(false)
                                                    setCurrentPage(
                                                        --currentPage
                                                    )
                                                    setCurrentPageTextBox(
                                                        currentPage + 1
                                                    )
                                                    getDocuments(
                                                        documentList[
                                                            documentSelected
                                                        ],
                                                        documentSelected
                                                    )
                                                }
                                            }}
                                        >
                                            <ArrowBackIcon />
                                        </IconButton>
                                        <TextField
                                            value={currentPageTextBox}
                                            type="number"
                                            onChange={(event) => {
                                                setCurrentPageTextBox(
                                                    event.target.value
                                                )
                                                if (
                                                    event.target.value >= 1 &&
                                                    event.target.value <=
                                                        (documentSelected !==
                                                            -1 &&
                                                            documentList[
                                                                documentSelected
                                                            ].pageCount)
                                                ) {
                                                    setStartValidation(false)
                                                    currentPage =
                                                        event.target.value - 1
                                                    setCurrentPage(currentPage)
                                                    getDocuments(
                                                        documentList[
                                                            documentSelected
                                                        ],
                                                        documentSelected
                                                    )
                                                }
                                            }}
                                            id="docPage"
                                            size="small"
                                            sx={{
                                                mt: '5px',
                                                width: '30px',
                                                '& .MuiOutlinedInput-root': {
                                                    height: '30px',
                                                },
                                                '& .MuiOutlinedInput-input': {
                                                    p: '7px',
                                                },
                                            }}
                                        />
                                        <FormLabel sx={{ mt: '11px' }}>
                                            /
                                            {(documentSelected !== -1 &&
                                                documentList[documentSelected]
                                                    .pageCount) ||
                                                1}
                                        </FormLabel>
                                        <IconButton
                                            title="Next Page"
                                            disabled={
                                                documentSelected === -1 ||
                                                (documentSelected !== -1 &&
                                                    documentList[
                                                        documentSelected
                                                    ].pageCount -
                                                        1 ===
                                                        currentPage)
                                            }
                                            onClick={() => {
                                                if (
                                                    documentList[
                                                        documentSelected
                                                    ].pageCount -
                                                        1 ===
                                                    currentPage
                                                ) {
                                                    return false
                                                } else {
                                                    setStartValidation(false)
                                                    setCurrentPage(
                                                        ++currentPage
                                                    )
                                                    setCurrentPageTextBox(
                                                        currentPage + 1
                                                    )
                                                    getDocuments(
                                                        documentList[
                                                            documentSelected
                                                        ],
                                                        documentSelected
                                                    )
                                                }
                                            }}
                                        >
                                            <ArrowForwardIcon />
                                        </IconButton>
                                        {!viewerFull && (
                                            <IconButton
                                                title="Full Screen"
                                                onClick={() =>
                                                    setViewerFull(true)
                                                }
                                            >
                                                <Fullscreen />
                                            </IconButton>
                                        )}
                                        {viewerFull && (
                                            <IconButton
                                                title="Exit Full Screen"
                                                onClick={() =>
                                                    setViewerFull(false)
                                                }
                                            >
                                                <FullscreenExitOutlinedIcon />
                                            </IconButton>
                                        )}
                                    </Stack>
                                )}
                            </Stack>
                        </Box>
                    </Grid>
                )}
                {!viewerFull && (
                    <Grid
                        item
                        xs={
                            !listVisible
                                ? tabsFull
                                    ? 12
                                    : 4
                                : tabsFull
                                ? 12
                                : 6
                        }
                    >
                        <Box>
                            <Stack direction="row" justifyContent="flex-end">
                                {props.showUploadIcon &&
                                    props.pipelineFullData !== null &&
                                    props.pipelineFullData.status !==
                                        'PAUSED' && (
                                        <UploadFile
                                            pipelineData={props.pipelineData}
                                            uploadedData={getUploadedData}
                                            setLoader={updateLoader}
                                        />
                                    )}
                                {props.editable &&
                                    documentSelected !== -1 &&
                                    !startValidation &&
                                    documentList.length > 0 &&
                                    documentList[documentSelected].pipeline
                                        .status !== 'PAUSED' && (
                                        <Button
                                            variant="contained"
                                            title="Start Validation"
                                            color="warning"
                                            size="small"
                                            sx={{
                                                borderRadius: '20px',
                                                minWidth: '90px',
                                            }}
                                            startIcon={<RuleOutlinedIcon />}
                                            onClick={() => {
                                                setStartValidation(true)
                                            }}
                                            disabled={
                                                localStorage.getItem(
                                                    'accessToken'
                                                ) !== null &&
                                                (fetchRoles().includes(
                                                    'Reviewer'
                                                ) ||
                                                    fetchRoles().includes(
                                                        'PipelineAdmin'
                                                    )) &&
                                                piiDataList !== null &&
                                                piiDataList.length > 0
                                            }
                                        >
                                            Start
                                        </Button>
                                    )}
                                {props.editable &&
                                    documentSelected !== -1 &&
                                    startValidation && (
                                        <Button
                                            variant="contained"
                                            title="Submit"
                                            color="success"
                                            size="small"
                                            sx={{ borderRadius: '20px' }}
                                            startIcon={<TaskAltOutlinedIcon />}
                                            onClick={() => {
                                                setStartValidation(false)
                                                submitData()
                                            }}
                                        >
                                            Submit
                                        </Button>
                                    )}
                                {!tabsFull && (
                                    <IconButton
                                        title="Full Screen"
                                        onClick={() => setTabsFull(true)}
                                    >
                                        <Fullscreen />
                                    </IconButton>
                                )}
                                {tabsFull && (
                                    <IconButton
                                        title="Exit Full Screen"
                                        onClick={() => {
                                            setTabsFull(false)
                                            setTimeout(() => {
                                                drawImageWithSize(
                                                    docSize,
                                                    imageObj
                                                )
                                            }, 100)
                                        }}
                                    >
                                        <FullscreenExitOutlinedIcon />
                                    </IconButton>
                                )}
                            </Stack>
                        </Box>
                    </Grid>
                )}
            </Grid>
            <Grid
                container
                sx={{
                    border: '1px solid #0000003b',
                    mt: 1,
                    minHeight: '455px',
                }}
            >
                {!(listVisible || viewerFull || tabsFull) && (
                    <Grid
                        item
                        xs={2.5}
                        sx={{
                            borderRight: '1px solid #0000003b',
                            overflowY: 'auto',
                            maxHeight: '455px',
                            minHeight: '455px',
                            textAlign: 'center',
                        }}
                    >
                        <Box key={'docBox'}>
                            <Slide
                                direction="down"
                                in={menuDrawer}
                                mountOnEnter
                                unmountOnExit
                            >
                                <Box>
                                    <Card
                                        raised={true}
                                        sx={{
                                            m: 1,
                                            border: '1px solid grey',
                                        }}
                                    >
                                        <List>
                                            {props.showPipelineName && (
                                                <ListItem>
                                                    <TextField
                                                        size="small"
                                                        fullWidth
                                                        label="Pipeline Name"
                                                        name="pipelineName"
                                                        value={
                                                            pipelineName || ''
                                                        }
                                                        onChange={(event) =>
                                                            setPipelineName(
                                                                event.target
                                                                    .value
                                                            )
                                                        }
                                                    />
                                                </ListItem>
                                            )}
                                            <ListItem>
                                                <TextField
                                                    size="small"
                                                    fullWidth
                                                    label="Document Name"
                                                    name="documentName"
                                                    value={documentName || ''}
                                                    onChange={(event) =>
                                                        setDocumentName(
                                                            event.target.value
                                                        )
                                                    }
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <Box>
                                                    <LocalizationProvider
                                                        dateAdapter={
                                                            AdapterDateFns
                                                        }
                                                    >
                                                        <DatePicker
                                                            label="Date From"
                                                            value={fromDate}
                                                            inputFormat="MM/dd/yyyy"
                                                            onChange={(
                                                                newValue
                                                            ) => {
                                                                setFromDate(
                                                                    newValue
                                                                )
                                                            }}
                                                            renderInput={(
                                                                params
                                                            ) => (
                                                                <TextField
                                                                    {...params}
                                                                    size="small"
                                                                    fullWidth
                                                                    name="fromDate"
                                                                />
                                                            )}
                                                        ></DatePicker>
                                                    </LocalizationProvider>
                                                </Box>
                                            </ListItem>
                                            <ListItem>
                                                <Box>
                                                    <LocalizationProvider
                                                        dateAdapter={
                                                            AdapterDateFns
                                                        }
                                                    >
                                                        <DatePicker
                                                            label="Date To"
                                                            value={toDate}
                                                            inputFormat="MM/dd/yyyy"
                                                            onChange={(
                                                                newValue
                                                            ) => {
                                                                setToDate(
                                                                    newValue
                                                                )
                                                            }}
                                                            renderInput={(
                                                                params
                                                            ) => (
                                                                <TextField
                                                                    size="small"
                                                                    fullWidth
                                                                    {...params}
                                                                    name="toDate"
                                                                />
                                                            )}
                                                        ></DatePicker>
                                                    </LocalizationProvider>
                                                </Box>
                                            </ListItem>
                                            {!props.showPipelineName && (
                                                <ListItem>
                                                    <Box>
                                                        <Typography
                                                            color={'#9196b1'}
                                                            fontWeight={'200'}
                                                        >
                                                            Status
                                                        </Typography>
                                                        <ToggleButtonGroup
                                                            value={docStatus}
                                                            aria-label="Document Status"
                                                            size="medium"
                                                            exclusive
                                                        >
                                                            <ToggleButton
                                                                value="IN_PROGRESS"
                                                                title="In Progress"
                                                                sx={{
                                                                    p: '13px',
                                                                }}
                                                                onClick={() =>
                                                                    handleDocStatus(
                                                                        'IN_PROGRESS'
                                                                    )
                                                                }
                                                            >
                                                                <LoopOutlinedIcon color="primary" />
                                                            </ToggleButton>
                                                            <ToggleButton
                                                                value="COMPLETED"
                                                                title="Completed"
                                                                sx={{
                                                                    p: '13px',
                                                                }}
                                                                onClick={() =>
                                                                    handleDocStatus(
                                                                        'COMPLETED'
                                                                    )
                                                                }
                                                            >
                                                                <CheckCircleOutlineOutlinedIcon color="success" />
                                                            </ToggleButton>
                                                            <ToggleButton
                                                                value="VALIDATION_FAILED"
                                                                title="Validation Failed"
                                                                sx={{
                                                                    p: '13px',
                                                                }}
                                                                onClick={() =>
                                                                    handleDocStatus(
                                                                        'VALIDATION_FAILED'
                                                                    )
                                                                }
                                                            >
                                                                <RuleOutlinedIcon color="warning" />
                                                            </ToggleButton>
                                                            <ToggleButton
                                                                value="FAILED"
                                                                title="Failed"
                                                                sx={{
                                                                    p: '13px',
                                                                }}
                                                                onClick={() =>
                                                                    handleDocStatus(
                                                                        'FAILED'
                                                                    )
                                                                }
                                                            >
                                                                <GppBadOutlinedIcon color="error" />
                                                            </ToggleButton>
                                                        </ToggleButtonGroup>
                                                    </Box>
                                                </ListItem>
                                            )}
                                            <Box sx={{ textAlign: 'right' }}>
                                                <Button
                                                    variant="outlined"
                                                    size="small"
                                                    sx={{ mr: 1 }}
                                                    color={'inherit'}
                                                    onClick={() => {
                                                        setDocumentSelected(-1)
                                                        setCurrentListPage(0)
                                                        setCurrentListPageTextBox(
                                                            0 + 1
                                                        )
                                                        docStatus = null
                                                        setDocStatus(docStatus)
                                                        setPipelineName(null)
                                                        setDocumentName(null)
                                                        setFromDate(null)
                                                        setToDate(null)
                                                        pipelineName = null
                                                        documentName = null
                                                        fromDate = null
                                                        toDate = null
                                                        setStartValidation(
                                                            false
                                                        )
                                                        submitFilter()
                                                        setMenuDrawer(
                                                            !menuDrawer
                                                        )
                                                        props.updatePage(0)
                                                    }}
                                                >
                                                    Clear
                                                </Button>
                                                <Button
                                                    variant="outlined"
                                                    size="small"
                                                    sx={{ mr: 2 }}
                                                    onClick={() => {
                                                        setDocumentSelected(-1)
                                                        setCurrentListPage(0)
                                                        setCurrentListPageTextBox(
                                                            0 + 1
                                                        )
                                                        setStartValidation(
                                                            false
                                                        )
                                                        submitFilter()
                                                        setMenuDrawer(
                                                            !menuDrawer
                                                        )
                                                        props.updatePage(0)
                                                    }}
                                                >
                                                    Apply
                                                </Button>
                                            </Box>
                                        </List>
                                    </Card>
                                </Box>
                            </Slide>
                            {!menuDrawer && documentList.length > 0 && (
                                <List sx={{ p: 0 }} key={'docList'}>
                                    {documentList.length > 0 &&
                                        documentList.map((val, index) => {
                                            return (
                                                <Box key={index}>
                                                    <Stack direction={'row'}>
                                                        <ListItemButton
                                                            title={val.name}
                                                            selected={
                                                                documentSelected ===
                                                                index
                                                            }
                                                            onClick={() => {
                                                                setStartValidation(
                                                                    false
                                                                )
                                                                currentPage = 0
                                                                setCurrentPage(
                                                                    0
                                                                )
                                                                getDocuments(
                                                                    val,
                                                                    index
                                                                )
                                                                setCurrentPageTextBox(
                                                                    1
                                                                )
                                                                setListVisible(
                                                                    true
                                                                )
                                                                props.selectedDocument(
                                                                    val
                                                                )
                                                            }}
                                                            dense={false}
                                                            sx={{
                                                                pt: '7px',
                                                                pl: '8px',
                                                                pr: '8px',
                                                                '&:hover': {
                                                                    backgroundColor:
                                                                        '#fff',
                                                                },
                                                            }}
                                                            key={'lb' + index}
                                                        >
                                                            <ListItemIcon
                                                                sx={{
                                                                    minWidth: 30,
                                                                    color:
                                                                        'inherit',
                                                                }}
                                                                key={
                                                                    'li' + index
                                                                }
                                                            >
                                                                {val.mimeType ===
                                                                    'image/png' ||
                                                                val.mimeType ===
                                                                    'image/jpeg' ? (
                                                                    <ImageOutlinedIcon />
                                                                ) : (
                                                                    <PictureAsPdfOutlinedIcon />
                                                                )}
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                key={
                                                                    'lt' + index
                                                                }
                                                            >
                                                                {val.classifications !==
                                                                    null && (
                                                                    <Box
                                                                        sx={{
                                                                            fontSize: 10,
                                                                            textColor:
                                                                                '#949db2',
                                                                        }}
                                                                    >
                                                                        <Stack
                                                                            direction="row"
                                                                            justifyContent="flex-end"
                                                                        >
                                                                            <Box title={Math.round(
                                                                                                val
                                                                                                    .classifications[0]
                                                                                                    .confidence *
                                                                                                    100
                                                                                            )+'%'}>
                                                                                <i>
                                                                                    <u>
                                                                                        {
                                                                                            val
                                                                                                .classifications[0]
                                                                                                .category
                                                                                        }
                                                                                    </u>
                                                                                </i>
                                                                            </Box>
                                                                        </Stack>
                                                                    </Box>
                                                                )}
                                                                <Box
                                                                    sx={{
                                                                        overflow:
                                                                            'hidden',
                                                                        textOverflow:
                                                                            'ellipsis',
                                                                        whiteSpace:
                                                                            'nowrap',
                                                                    }}
                                                                >
                                                                    {val.name}
                                                                </Box>
                                                                {props.showPipelineName && (
                                                                    <Box
                                                                        sx={{
                                                                            overflow:
                                                                                'hidden',
                                                                            textOverflow:
                                                                                'ellipsis',
                                                                            whiteSpace:
                                                                                'nowrap',
                                                                            fontSize:
                                                                                '12px',
                                                                            color:
                                                                                '#949db2',
                                                                        }}
                                                                    >
                                                                        {
                                                                            val
                                                                                .pipeline
                                                                                .name
                                                                        }
                                                                    </Box>
                                                                )}
                                                                <Box>
                                                                    <Stack
                                                                        direction="row"
                                                                        justifyContent="space-between"
                                                                    >
                                                                        <Chip
                                                                            label={
                                                                                val.status
                                                                            }
                                                                            size="small"
                                                                            variant="outlined"
                                                                            color={
                                                                                val.status ===
                                                                                'VALIDATION_FAILED'
                                                                                    ? 'error'
                                                                                    : val.status ===
                                                                                      'PENDING'
                                                                                    ? 'warning'
                                                                                    : val.status ===
                                                                                      'FAILED'
                                                                                    ? 'error'
                                                                                    : val.status ===
                                                                                      'IN_PROGRESS'
                                                                                    ? 'primary'
                                                                                    : 'success'
                                                                            }
                                                                            sx={{
                                                                                fontSize: 8.5,
                                                                            }}
                                                                            icon={
                                                                                <CachedIcon
                                                                                    sx={{
                                                                                        display:
                                                                                            val.status ===
                                                                                                'PENDING' ||
                                                                                            val.status ===
                                                                                                'IN_PROGRESS'
                                                                                                ? 'block'
                                                                                                : 'none',
                                                                                        animation:
                                                                                            'icon-spin infinite 2s linear',
                                                                                    }}
                                                                                />
                                                                            }
                                                                        />
                                                                        <Chip
                                                                            label={
                                                                                <Moment format="DD/MM/YYYY">
                                                                                    {
                                                                                        val.createdOn
                                                                                    }
                                                                                </Moment>
                                                                            }
                                                                            size="small"
                                                                            variant="outlined"
                                                                            sx={{
                                                                                border:
                                                                                    'none',
                                                                                color:
                                                                                    '#949db2',
                                                                                fontSize: 8.5,
                                                                            }}
                                                                        ></Chip>
                                                                    </Stack>
                                                                </Box>
                                                            </ListItemText>
                                                        </ListItemButton>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent:
                                                                    'center',
                                                                alignItems:
                                                                    'center',
                                                            }}
                                                            onClick={() => {
                                                                setDeleteDocId(
                                                                    val.id
                                                                )
                                                                setDeletePipelineId(
                                                                    props.showPipelineName
                                                                        ? val
                                                                              .pipeline
                                                                              .id
                                                                        : props.pipelineData
                                                                )
                                                                setAlertValue(
                                                                    true
                                                                )
                                                            }}
                                                        >
                                                            {!props.editable && (
                                                                <Slide
                                                                    direction="left"
                                                                    in={true}
                                                                    mountOnEnter
                                                                    unmountOnExit
                                                                >
                                                                    <IconButton
                                                                        size="small"
                                                                        sx={{
                                                                            p:
                                                                                '1px',
                                                                        }}
                                                                    >
                                                                        <DeleteOutline
                                                                            sx={{
                                                                                fontSize:
                                                                                    '16px',
                                                                            }}
                                                                        />
                                                                    </IconButton>
                                                                </Slide>
                                                            )}
                                                        </Box>
                                                    </Stack>
                                                    <Divider />
                                                </Box>
                                            )
                                        })}
                                </List>
                            )}
                        </Box>
                        {documentList.length === 0 && (
                            <Box
                                sx={{
                                    mt: 'calc((455px / 2) - 80px)',
                                }}
                            >
                                <ListOutlinedIcon
                                    sx={{ fontSize: 80, color: '#dcdcdc' }}
                                />
                                <Typography color={'#dcdcdc'}>
                                    No List Available
                                </Typography>
                            </Box>
                        )}
                    </Grid>
                )}
                {!tabsFull && (
                    <Grid
                        item
                        xs={viewerFull ? 12 : !listVisible ? 5.5 : 6}
                        sx={{ borderRight: '1px solid #0000003b' }}
                    >
                        <Box
                            id="viewer"
                            style={{
                                overflow: 'auto',
                                textAlign: 'center',
                                maxHeight: 455,
                            }}
                        >
                            {documentSelected === -1 && (
                                <Box
                                    sx={{
                                        position: 'relative',
                                        mt: 'calc((455px / 2) - 80px)',
                                    }}
                                >
                                    <ImageOutlinedIcon
                                        sx={{ fontSize: 80, color: '#dcdcdc' }}
                                    />
                                    <Typography color={'#dcdcdc'}>
                                        No Document Available
                                    </Typography>
                                </Box>
                            )}
                            {documentSelected !== -1 && (
                                <canvas id="viewerCanvas"></canvas>
                            )}
                        </Box>
                    </Grid>
                )}
                {!viewerFull && (
                    <Grid
                        item
                        xs={
                            !listVisible
                                ? tabsFull
                                    ? 12
                                    : 4
                                : tabsFull
                                ? 12
                                : 6
                        }
                        sx={{ borderRight: '1px solid #0000003b' }}
                    >
                        <Box sx={{ overflow: 'auto', maxHeight: 455 }}>
                            <Grid container>
                                <Grid
                                    item
                                    xs={
                                        tabsFull
                                            ? 11.1
                                            : !listVisible
                                            ? 9.25
                                            : 10.2
                                    }
                                >
                                    {tabSelected === 0 && (
                                        <Box
                                            sx={{
                                                overflowY: 'auto',
                                                maxHeight: 455,
                                            }}
                                        >
                                            {dataExtractionStepName === 'comprehend-medical-queue' ? (
												<MedicalEntitiesData
	                                                docData={
	                                                    documentSelected !== -1
	                                                        ? documentList[
	                                                              documentSelected
	                                                          ]
	                                                        : null
	                                                }
	                                                drawPoint={drawRect}
	                                                dataExtractionStepName={dataExtractionStepName}
                                                    medicalExtractionType='medical-phi'
                                                />
                                            ) : (
	                                            <FormDatas
	                                                docData={
	                                                    documentSelected !== -1
	                                                        ? documentList[
	                                                              documentSelected
	                                                          ]
	                                                        : null
	                                                }
	                                                page={currentPage}
	                                                drawPoint={drawRect}
	                                                editable={props.editable}
	                                                getInvalidData={getFormData}
	                                                getValidationStatus={
	                                                    startValidation
	                                                }
	                                                tabsStatus={
	                                                    tabsFull
	                                                        ? 35
	                                                        : !listVisible
	                                                        ? 7
	                                                        : 14
	                                                }
	                                            />
                                            )}
                                        </Box>
                                    )}
                                    {tabSelected === 1 && (
                                        <Box
                                            sx={{
                                                overflowY: 'auto',
                                                maxHeight: 455,
                                            }}
                                        >
                                            {dataExtractionStepName === 'comprehend-medical-queue' ? (
                                                <MedicalEntitiesData
                                                    docData={
                                                        documentSelected !== -1
                                                            ? documentList[
                                                                  documentSelected
                                                              ]
                                                            : null
                                                    }
                                                    drawPoint={drawRect}
	                                                dataExtractionStepName={dataExtractionStepName}
                                                    medicalExtractionType='medical-rxnorm'
                                                />
                                            ) : (
	                                            <TableData
	                                                docData={
	                                                    documentSelected !== -1
	                                                        ? documentList[
	                                                              documentSelected
	                                                          ]
	                                                        : null
	                                                }
	                                                page={currentPage}
	                                                drawPoint={drawRect}
	                                            />
	                                        )}
                                        </Box>
                                    )}
                                    {tabSelected === 2 && (
                                        <Box
                                            sx={{
                                                overflowY: 'auto',
                                                maxHeight: 455,
                                            }}
                                        >
                                            {dataExtractionStepName === 'comprehend-medical-queue' ? (
                                                <MedicalEntitiesData
                                                    docData={
                                                        documentSelected !== -1
                                                            ? documentList[
                                                                  documentSelected
                                                              ]
                                                            : null
                                                    }
                                                    drawPoint={drawRect}
	                                                dataExtractionStepName={dataExtractionStepName}
                                                    medicalExtractionType='medical-icd10cm'
                                                />
                                            ) : (
	                                            <TextData
	                                                docData={
	                                                    documentSelected !== -1
	                                                        ? documentList[
	                                                              documentSelected
	                                                          ]
	                                                        : null
	                                                }
                                                    pipelineData={props.pipelineFullData}
	                                            />
	                                        )}

                                        </Box>
                                    )}
                                    {tabSelected === 3 && (
                                        <Box
                                            sx={{
                                                overflowY: 'auto',
                                                maxHeight: 455,
                                            }}
                                        >
                                            {dataExtractionStepName === 'comprehend-medical-queue' ? (
                                                <MedicalEntitiesData
                                                    docData={
                                                        documentSelected !== -1
                                                            ? documentList[
                                                                  documentSelected
                                                              ]
                                                            : null
                                                    }
                                                    drawPoint={drawRect}
	                                                dataExtractionStepName={dataExtractionStepName}
                                                    medicalExtractionType='medical-entity'
                                                />
                                            ) : (
	                                            <EntitiesData
	                                                docData={
	                                                    documentSelected !== -1
	                                                        ? documentList[
	                                                              documentSelected
	                                                          ]
	                                                        : null
	                                                }
	                                                drawPoint={drawRect}
	                                            />
	                                        )}
                                        </Box>
                                    )}
                                    {tabSelected === 4 && (
                                        <Box
                                            sx={{
                                                overflowY: 'auto',
                                                maxHeight: 455,
                                            }}
                                        >
                                            {dataExtractionStepName === 'comprehend-medical-queue' ? (
                                                <MedicalEntitiesData
                                                    docData={
                                                        documentSelected !== -1
                                                            ? documentList[
                                                                  documentSelected
                                                              ]
                                                            : null
                                                    }
                                                    drawPoint={drawRect}
	                                                dataExtractionStepName={dataExtractionStepName}
                                                    medicalExtractionType='medical-snomedct'
                                                />
                                            ) : (
	                                            <SummaryData
	                                                docData={
	                                                    documentSelected !== -1
	                                                        ? documentList[
	                                                              documentSelected
	                                                          ]
	                                                        : null
	                                                }
	                                                drawPoint={drawRect}
	                                            />
	                                        )}
                                        </Box>
                                    )}
                                    {tabSelected === 5 && (
                                        <Box
                                            sx={{
                                                overflowY: 'auto',
                                                maxHeight: 455,
                                            }}
                                        >
                                            <SentimentData
                                                docData={
                                                    documentSelected !== -1
                                                        ? documentList[
                                                                documentSelected
                                                            ]
                                                        : null
                                                }
                                                drawPoint={drawRect}
                                            />
                                        </Box>
                                    )}
                                </Grid>
                                <Grid
                                    item
                                    xs={
                                        tabsFull
                                            ? 0.9
                                            : !listVisible
                                            ? 2.75
                                            : 1.8
                                    }
                                >
                                    <Tabs
                                        onChange={tabChange}
                                        variant="fullWidth"
                                        scrollButtons="auto"
                                        textColor="inherit"
                                        value={tabSelected}
                                        sx={{
                                            backgroundColor: '#252b45',
                                            color: '#fff',
                                            marginLeft: 'calc(100% - 90px)',
                                            '& button': {
                                                minHeight: 75.8,
                                                p: 0,
                                            },
                                            '& .MuiTabs-indicator': {
                                                width: '20px',
                                                height: '20px !important',
                                                marginRight: '70px',
                                                transformOrigin: 'center',
                                                transform: 'rotate(45deg)',
                                                marginTop: '25%',
                                                left: '-10px',
                                                position: 'absolute',
                                                border: '10px solid',
                                            },
                                        }}
                                        orientation="vertical"
                                    >
                                        <Tab
                                            label={dataExtractionStepName === 'comprehend-medical-queue' ? "Phi" : "Forms"}
                                            value={0}
                                            icon={<ArticleOutlinedIcon />}
                                        />
                                        <Tab
                                            label={dataExtractionStepName === 'comprehend-medical-queue' ? "RxNorm" : "Tables"}
                                            value={1}
                                            icon={<TableRowsOutlinedIcon />}
                                        />
                                        <Tab
                                            label={dataExtractionStepName === 'comprehend-medical-queue' ? "ICD10CM" : "Text"}
                                            value={2}
                                            icon={<AbcOutlinedIcon />}
                                        />
                                        <Tab
                                            label={dataExtractionStepName === 'comprehend-medical-queue' ? "Entities" : "Entities"}
                                            value={3}
                                            icon={<LanOutlinedIcon />}
                                        />
                                        <Tab
                                            label={dataExtractionStepName === 'comprehend-medical-queue' ? "SNOMEDCT" : "Summary"}
                                            value={4}
                                            icon={<FactCheckOutlinedIcon />}
                                        />
                                        {dataExtractionStepName !== 'comprehend-medical-queue' && (
                                            <Tab
												label="Sentiments"
												value={5}
												icon={<SentimentNeutralOutlinedIcon />}
                                            />
                                        )}

                                    </Tabs>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                )}
            </Grid>
            <Alert
                value={openAlertValue}
                updateAlertDialog={setAlertDialog}
                updateYesButtonStatus={setYesButtonStatus}
            />
        </Box>
    )
}

export default ListViewer
