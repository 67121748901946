import AuthGuard from 'app/auth/AuthGuard'
import NotFound from 'app/views/sessions/NotFound'
import sessionRoutes from 'app/views/sessions/SessionRoutes'
import MatxLayout from '../components/MatxLayout/MatxLayout'
import { Navigate } from 'react-router-dom'
import pipelineRoute from 'app/views/pipeline/PipelineRoute'
import InboxRoute from 'app/views/inbox/InboxRoute'
import SearchRoute from 'app/views/search/SearchRoute'
import ConnectorsRoute from 'app/views/connectors/ConnectorsRoute'
import ReportsRoute from 'app/views/reports/ReportsRoute'

export const AllPages = () => {
    const all_routes = [
        {
            element: (
                <AuthGuard>
                    <MatxLayout />
                </AuthGuard>
            ),
            children: [
                ...pipelineRoute,
                ...InboxRoute,
                ...SearchRoute,
                ...ConnectorsRoute,
                ...ReportsRoute,
            ],
        },
        ...sessionRoutes,
        {
            path: '/',
            element: <Navigate to="/dashboard" />,
        },
        {
            path: '*',
            element: <NotFound />,
        },
    ]

    return all_routes
}
