import React, { useState, useEffect, useCallback } from 'react'
import {
    Box,
    IconButton,
    Switch,
    Chip,
    Backdrop,
    CircularProgress,
} from '@mui/material'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteOutline from '@mui/icons-material/DeleteOutline'
import VisibilityIcon from '@mui/icons-material/Visibility'
import Alert from '../../../components/Dialog/Alert'
import { getData, putData, deleteData } from '../../../services/Api'
import { DataGrid } from '@mui/x-data-grid'
import Moment from 'react-moment'
import RenderCellExpand from 'app/components/RenderCellExpand'
import { useStyles } from '../TableTheme'
import InfoAlert from '../../../components/Dialog/InfoAlert'
import { useNavigate } from 'react-router-dom'

const PipelineTable = (props) => {
    const [pipelineList, updateList] = useState([])
    const [page, setPage] = React.useState(0)
    const [totalElements, setTotalElements] = React.useState(0)
    const [openAlertValue, setAlertValue] = useState(false)
    const [openInfoAlertValue, setInfoAlertValue] = useState(false)
    let [yesButtonStatus, setButtonStatus] = useState(false)
    let [indexValue, updateIndexValue] = useState()
    let [infoTitle, setInfoTitle] = useState()
    const cssClass = useStyles()
    let navigate = useNavigate()
    let [getFilterData, updateFilterData] = useState(null)
    const [loader, setLoader] = useState(false)

    const piplineColumns = [
        {
            field: 'name',
            headerName: 'Name',
            minWidth: 250,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            renderCell: RenderCellExpand,
        },
        {
            field: 'desc',
            headerName: 'Description',
            minWidth: 300,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            renderCell: RenderCellExpand,
        },
        {
            field: 'createdBy',
            headerName: 'Created By',
            minWidth: 130,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            disableColumnMenu: true,
            renderCell: RenderCellExpand,
        },
        {
            field: 'createdOn',
            headerName: 'Created On',
            minWidth: 200,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            disableColumnMenu: true,
            renderCell: (val) => {
                return (
                    <Moment format="DD/MM/YYYY - HH:mm">
                        {val.row.createdOn}
                    </Moment>
                )
            },
        },
        {
            field: 'status',
            headerName: 'Status',
            minWidth: 150,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            disableColumnMenu: true,
            renderCell: (val) => {
                return (
                    <Chip
                        sx={{
                            pl: '4px',
                            pr: '4px',
                            color: '#fff',
                            backgroundColor:
                                val.row.status === 'CREATED'
                                    ? 'primary.main'
                                    : val.row.status === 'PAUSED'
                                    ? '#FF3D57'
                                    : val.row.status === 'RUNNING'
                                    ? 'success.main'
                                    : '',
                            minWidth: '90px',
                        }}
                        size="small"
                        label={val.row.status}
                    ></Chip>
                )
            },
        },
        {
            field: 'action',
            headerName: 'Actions',
            minWidth: 180,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            align: 'center',
            renderCell: (val, index) => {
                return (
                    <Box>
                        <IconButton
                            color="primary"
                            aria-label="Pipeline Details"
                            title="Pipeline Details"
                            onClick={()=>{navigate('/pipeline-details?pipline-id=' + val.id)}}
                            disabled={val.row.status === 'CREATED'}
                        >
                            <VisibilityIcon />
                        </IconButton>
                        <IconButton
                            color="warning"
                            aria-label="Edit"
                            title="Edit Pipeline"
                            onClick={() => gotoEdit(val)}
                            disabled={
                                localStorage.getItem('accessToken') !== null &&
                                fetchRoles().includes('Reviewer')
                            }
                        >
                            <EditOutlinedIcon />
                        </IconButton>

                        <Switch
                            title={
                                val.row.status === 'CREATED' ||
                                val.row.status === 'PAUSED'
                                    ? 'Resume Pipeline'
                                    : 'Pause Pipeline'
                            }
                            disabled={
                                localStorage.getItem('accessToken') !== null &&
                                fetchRoles().includes('Reviewer')
                            }
                            color="primary"
                            aria-label="Pipeline"
                            defaultChecked={
                                val.row.status === 'CREATED' ||
                                val.row.status === 'PAUSED'
                                    ? false
                                    : true
                            }
                            onChange={toggleChecked(val)}
                        ></Switch>
                        <IconButton
                            color="error"
                            aria-label="Delete"
                            title="Delete Pipeline"
                            onClick={() => openAlert(val)}
                            disabled={
                                localStorage.getItem('accessToken') !== null &&
                                fetchRoles().includes('Reviewer')
                            }
                        >
                            <DeleteOutline />
                        </IconButton>
                    </Box>
                )
            },
        },
    ]

    const setAlertDialog = (value) => {
        setAlertValue(value)
    }

    const setInfoAlertDialog = (value) => {
        setInfoAlertValue(value)
    }

    const getIndex = (val) => {
        let rowNum = -1
        pipelineList.forEach((value, index) => {
            if (val.id === value.id) {
                rowNum = index
            }
        })
        return rowNum
    }

    const openAlert = (val) => {
        if (val.row.status === 'RUNNING') {
            setInfoTitle("Pipeline is Running! You can't Delete.")
            openInfoAlert(val)
        } else {
            setAlertValue(true)
            updateIndexValue(getIndex(val))
        }
    }

    const gotoEdit = (val) => {
        if (val.row.status === 'RUNNING') {
            setInfoTitle("Pipeline is Running! You can't Edit.")
            openInfoAlert(val)
        } else {
            navigate('/create-pipeline?pipline-id=' + val.id)
        }
    }

    const openInfoAlert = (val) => {
        setInfoAlertValue(true)
    }

    const setYesButtonStatus = (value) => {
        setButtonStatus(value)
        deletePipelinData(pipelineList[indexValue])
    }

    useEffect(() => {
        if (yesButtonStatus) {
            setButtonStatus(false)
        }
    }, [yesButtonStatus, indexValue])

    const getPipelinData = useCallback(() => {
        let url = '/core/pipeline?page=' + page
        if (
            getFilterData !== null &&
            getFilterData.pipelineName !== '' &&
            getFilterData.pipelineName !== null
        ) {
            url = url + '&pipelineName=' + getFilterData.pipelineName
        }
        if (
            getFilterData !== null &&
            getFilterData.fromDate !== '' &&
            getFilterData.toDate !== '' &&
            getFilterData.fromDate !== null &&
            getFilterData.toDate !== null
        ) {
            url =
                url +
                '&fromDate=' +
                getFilterData.fromDate +
                '&toDate=' +
                getFilterData.toDate
        }
        if (
            getFilterData !== null &&
            getFilterData.status !== '' &&
            getFilterData.status !== null
        ) {
            url = url + '&pipelineStatus=' + getFilterData.status
        }
        setLoader(true)
        getData(url).then((data) => {
            setLoader(false)
            if (data !== null) {
                updateList(data.content)
                setTotalElements(data.totalElements)
            }
        })
    }, [page, getFilterData, setLoader])

    const deletePipelinData = (value) => {
        setLoader(true)
        deleteData('/core/pipeline/' + value.id).then((data) => {
            getPipelinData()
        })
    }

    useEffect(() => {
        getPipelinData()
    }, [getPipelinData])

    useEffect(() => {
        updateFilterData(props.getFilterData)
    }, [props.getFilterData])

    const handleChangePage = (newPage) => {
        setPage(newPage)
    }

    const fetchRoles = () => {
        var payload = Buffer.from(
            localStorage.getItem('idToken').split('.')[1],
            'base64'
        )
        const roles = JSON.parse(payload.toString())['cognito:groups']
        return roles[0];
    }

    const toggleChecked = (value) => (event) => {
        if (event.target.checked) {
            setLoader(true)
            putData('/core/pipeline/' + value.id + '/resume').then((data) => {
                getPipelinData()
            })
        } else if (!event.target.checked) {
            setLoader(true)
            putData('/core/pipeline/' + value.id + '/pause').then((data) => {
                getPipelinData()
            })
        }
    }

    return (
        <div style={{ height: 415, width: '100%', marginTop: '50px' }}>
            <DataGrid
                rows={pipelineList}
                columns={piplineColumns}
                pageSize={10}
                onPageChange={(page) => handleChangePage(page)}
                pagination
                rowCount={totalElements}
                rowHeight={32}
                headerHeight={40}
                disableSelectionOnClick={true}
                className={cssClass.root}
                resizable={true}
                showCellRightBorder={true}
                showColumnRightBorder={true}
                paginationMode="server"
            />
            <Alert
                value={openAlertValue}
                updateAlertDialog={setAlertDialog}
                updateYesButtonStatus={setYesButtonStatus}
            />
            <InfoAlert
                value={openInfoAlertValue}
                updateInfoAlertDialog={setInfoAlertDialog}
                infoTitle={infoTitle}
            />
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={loader}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}

export default PipelineTable
