const BASE_URL = 'https://mws0epp2c6.execute-api.ap-south-1.amazonaws.com'
// const BASE_URL = '/api/v1'

const setHeaders = () => {
    let headers = new Headers()
    headers.append('Content-Type', 'application/json')
    headers.append(
        'Authorization',
        'Bearer ' + localStorage.getItem('accessToken')
    )
    return headers
}

const getData = async (url) => {
    if (localStorage.getItem('accessToken') !== null) {
        const response = await fetch(BASE_URL + url, {
            method: 'GET',
            headers: setHeaders(),
        })
        if (response.ok) {
            try {
                return await response.json()
            } catch (err) {
                return null
            }
        } else {
            clearStorege()
        }
    } else {
        window.location.replace('/pipeline')
    }
}

const getPlainText = async (url) => {
    const response = await fetch(BASE_URL + url, {
        method: 'GET',
        headers: setHeaders(),
    })
    if (response.ok) {
        return await response.text()
    } else {
        clearStorege()
    }
}

const getFileData = async (url) => {
    const response = await fetch(BASE_URL + url, {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
    })
    if (response.ok) {
        return await response.blob()
    } else {
        clearStorege()
    }
}

const putData = async (url) => {
    const response = await fetch(BASE_URL + url, {
        method: 'PUT',
        mode: 'cors',
        headers: setHeaders(),
    })
    if (response.ok) {
        try {
            return await response.json()
        } catch (err) {
            return null
        }
    }
}

const patchData = async (url, data) => {
    const response = await fetch(BASE_URL + url, {
        method: 'PUT',
        headers: setHeaders(),
        body: JSON.stringify(data),
    })
    return await response.json()
}

const deleteData = async (url) => {
    const response = await fetch(BASE_URL + url, {
        method: 'DELETE',
        mode: 'cors',
        headers: setHeaders(),
    })
    return await response
}

const postData = async (url, data) => {
    const response = await fetch(BASE_URL + url, {
        method: 'POST',
        headers: setHeaders(),
        body: JSON.stringify(data),
    })
    return await response
}

const fileUpload = async (url, data) => {
    const response = await fetch(BASE_URL + url, {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
        body: data,
    })
    return await response.json()
}

const clearStorege = () => {
    // localStorage.clear()
    // window.location.reload()
}

export {
    getData,
    putData,
    postData,
    deleteData,
    patchData,
    fileUpload,
    getFileData,
    getPlainText,
}
