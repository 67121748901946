import React from 'react'
import Inbox from './Inbox'

const InboxRoute = [
    {
        path: '/inbox',
        element: <Inbox />,
    },
]

export default InboxRoute
