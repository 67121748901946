import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Grid,
    TextField,
    Typography,
    FormLabel,
    LinearProgress,
    Card,
    CardContent,
    CardHeader,
    Slide,
    Fab,
    Checkbox,
    IconButton,
} from '@mui/material'
import React, { useState, useCallback, useEffect } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined'
import { getData } from '../../services/Api'
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined'
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined'
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined'
import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined'

const FormData = (props) => {
    const docData = props.docData
    const [formData, setFormData] = useState(null)
    const [invalidFormData, setInvalidFormData] = useState(null)
    let [startValidation, setStartValidation] = useState(false)
    let [textboxFocus, setTextBoxFocus] = useState(-1)
    let countTotalElement = -1
    let [forwardButton, setForwardButton] = useState(false)
    let [tabStatus, setTabStatus] = useState(1)

    const getFormData = useCallback(() => {
        if (docData !== null && docData.status !== 'PENDING') {
            if (props.editable && !startValidation) {
                getData(
                    '/core/pipeline/' +
                        docData.pipelineId +
                        '/document/' +
                        docData.id +
                        '/invalidformsdata'
                ).then((data) => {
                    if (data !== null) {
                        setInvalidFormData(data)
                    }
                })
            } else {
                getData(
                    '/core/pipeline/' +
                        docData.pipelineId +
                        '/document/' +
                        docData.id +
                        '/forms?pageNo=' +
                        (props.page + 1)
                ).then((data) => {
                    if (data !== null && data.length > 0) {
                        setFormData(
                            data.sort((a, b) => a.confidence - b.confidence)
                        )
                    } else {
                        setFormData(null)
                    }
                })
            }
        } else {
            setFormData(null)
            setInvalidFormData(null)
        }
    }, [docData, props.page, props.editable])

    useEffect(() => {
        getFormData()
    }, [getFormData])

    useEffect(() => {
        setTabStatus(props.tabsStatus)
    }, [props.tabsStatus])

    useEffect(() => {
        setStartValidation(props.getValidationStatus)
        setTimeout(() => {
            if (document.getElementById('textbox0') !== null) {
                setTextBoxFocus(0)
                document.getElementById('textbox0').focus()
            }
        }, 100)
    }, [props.getValidationStatus])

    const gotoPoint = (boundingBox, pageNo) => {
        props.drawPoint(boundingBox, pageNo)
    }

    const handleChange = (event, page, index) => {
        if (event.target.type === 'text') {
            invalidFormData[page + 1][index].value = event.target.value
        } else if (event.target.type === 'checkbox') {
            invalidFormData[page + 1][index].value = event.target.checked
                ? 'TRUE'
                : 'FALSE'
        }
        props.getInvalidData(invalidFormData)
    }

    const setButtonStatus = (val) => {
        if (val === 'Back') {
            setTextBoxFocus(--textboxFocus)
            document.getElementById('textbox' + textboxFocus).focus()
        } else {
            setTextBoxFocus(++textboxFocus)
            document.getElementById('textbox' + textboxFocus).focus()
        }
        if (textboxFocus === countTotalElement) {
            setForwardButton(true)
        } else {
            setForwardButton(false)
        }
    }

    const setFocusPoint = (event) => {
        setTextBoxFocus(parseInt(event.target.id.replace('textbox', '')))
        if (
            parseInt(event.target.id.replace('textbox', '')) ===
            countTotalElement
        ) {
            setForwardButton(true)
        } else {
            setForwardButton(false)
        }
    }

    return (
        <Box>

	            <Grid container>
	                <Grid item xs={12} key={'arrowButton'}>
	                    <Box
	                        sx={{
	                            position: 'fixed',
	                            zIndex: 99999,
	                            marginLeft: tabStatus + '%',
	                        }}
	                    >
	                        {startValidation && (
	                            <Slide
	                                direction="down"
	                                in={startValidation}
	                                mountOnEnter
	                                unmountOnExit
	                            >
	                                <Box>
	                                    <Fab
	                                        size="small"
	                                        aria-label="Back"
	                                        title="Back"
	                                        sx={{
	                                            m: 1,
	                                            color: '#fff',
	                                            backgroundColor: '#757575',
	                                            '&:hover': {
	                                                backgroundColor: '#757575bf',
	                                            },
	                                        }}
	                                        onClick={() => {
	                                            setButtonStatus('Back')
	                                        }}
	                                        disabled={
	                                            textboxFocus === -1 ||
	                                            textboxFocus === 0
	                                        }
	                                    >
	                                        <ArrowBackOutlinedIcon />
	                                    </Fab>
	                                    <Fab
	                                        size="small"
	                                        aria-label="Forward"
	                                        title="Forward"
	                                        sx={{
	                                            m: 1,
	                                            color: '#fff',
	                                            backgroundColor: '#757575',
	                                            '&:hover': {
	                                                backgroundColor: '#757575bf',
	                                            },
	                                        }}
	                                        onClick={() => {
	                                            setButtonStatus('Forward')
	                                        }}
	                                        disabled={forwardButton}
	                                    >
	                                        <ArrowForwardOutlinedIcon />
	                                    </Fab>
	                                </Box>
	                            </Slide>
	                        )}
	                    </Box>
	                </Grid>
	                <Box sx={{ display: 'none' }}>{(countTotalElement = -1)}</Box>
	                {props.editable &&
	                    invalidFormData !== null &&
	                    invalidFormData !== undefined &&
	                    Object.keys(invalidFormData).map((val, i) => (
	                        <Grid item xs={12} key={'edit' + i}>
	                            <Box sx={{ mt: startValidation ? 8 : 2 }}>
	                                <Grid item key={'grid' + i} xs={12}>
	                                    <Accordion
	                                        key={'accord' + i}
	                                        defaultExpanded={true}
	                                        disableGutters={true}
	                                        sx={{ mr: 1, ml: 1 }}
	                                    >
	                                        <AccordionSummary
	                                            expandIcon={<ExpandMoreIcon />}
	                                            key={'summary' + i}
	                                        >
	                                            {'Page ' + val}
	                                        </AccordionSummary>
	                                        <AccordionDetails
	                                            key={'details' + i}
	                                            sx={{ p: 1 }}
	                                        >
	                                            {invalidFormData[val].map(
	                                                (res, j) => (
	                                                    <Card
	                                                        variant="outlined"
	                                                        key={'carde' + j}
	                                                        sx={{
	                                                            mb: 2,
	                                                            border: !res.valid
	                                                                ? '1px solid red'
	                                                                : 'none',
	                                                        }}
	                                                        onClick={() =>
	                                                            gotoPoint(
	                                                                res.boundingBox,
	                                                                val
	                                                            )
	                                                        }
	                                                    >
	                                                        <Box
	                                                            sx={{
	                                                                display: 'none',
	                                                            }}
	                                                        >
	                                                            {' '}
	                                                            {
	                                                                ++countTotalElement
	                                                            }
	                                                        </Box>
	                                                        <CardHeader
	                                                            key={'heade' + j}
	                                                            subheader={res.key}
	                                                            sx={{
	                                                                p: 1,
	                                                                '& .MuiCardHeader-subheader': {
	                                                                    color:
	                                                                        'darkslategray',
	                                                                    fontWeight:
	                                                                        'bold',
	                                                                },
	                                                            }}
	                                                        ></CardHeader>
	                                                        <CardContent
	                                                            sx={{
	                                                                p: 1,
	                                                                pb:
	                                                                    '8px !important',
	                                                            }}
	                                                        >
	                                                            <Grid
	                                                                container
	                                                                spacing={2}
	                                                                key={
	                                                                    'containere' +
	                                                                    j
	                                                                }
	                                                            >
	                                                                <Grid
	                                                                    item
	                                                                    xs={10}
	                                                                    key={
	                                                                        'it2e' +
	                                                                        j
	                                                                    }
	                                                                >
	                                                                    {startValidation && (
	                                                                        <Box>
	                                                                            {res.type ===
	                                                                                'STRING' && (
	                                                                                <TextField
	                                                                                    defaultValue={
	                                                                                        res.value
	                                                                                    }
	                                                                                    name={
	                                                                                        res.key
	                                                                                    }
	                                                                                    onChange={(
	                                                                                        event
	                                                                                    ) =>
	                                                                                        handleChange(
	                                                                                            event,
	                                                                                            res.pageNo -
	                                                                                                1,
	                                                                                            j
	                                                                                        )
	                                                                                    }
	                                                                                    fullWidth
	                                                                                    key={
	                                                                                        'textbox' +
	                                                                                        j
	                                                                                    }
	                                                                                    id={
	                                                                                        'textbox' +
	                                                                                        countTotalElement
	                                                                                    }
	                                                                                    size="small"
	                                                                                    onFocus={(
	                                                                                        event
	                                                                                    ) => {
	                                                                                        gotoPoint(
	                                                                                            res.boundingBox,
	                                                                                            res.pageNo
	                                                                                        )
	                                                                                        setFocusPoint(
	                                                                                            event
	                                                                                        )
	                                                                                    }}
	                                                                                />
	                                                                            )}
	                                                                            {res.type ===
	                                                                                'CHECKBOX' && (
	                                                                                <Checkbox
	                                                                                    onChange={(
	                                                                                        event
	                                                                                    ) =>
	                                                                                        handleChange(
	                                                                                            event,
	                                                                                            res.pageNo -
	                                                                                                1,
	                                                                                            j
	                                                                                        )
	                                                                                    }
	                                                                                    id={
	                                                                                        'textbox' +
	                                                                                        countTotalElement
	                                                                                    }
	                                                                                    name={
	                                                                                        res.key +
	                                                                                        j
	                                                                                    }
	                                                                                    defaultChecked={
	                                                                                        res.value ===
	                                                                                        'TRUE'
	                                                                                            ? true
	                                                                                            : false
	                                                                                    }
	                                                                                    onFocus={(
	                                                                                        event
	                                                                                    ) => {
	                                                                                        gotoPoint(
	                                                                                            res.boundingBox,
	                                                                                            res.pageNo
	                                                                                        )
	                                                                                        setFocusPoint(
	                                                                                            event
	                                                                                        )
	                                                                                    }}
	                                                                                />
	                                                                            )}
	                                                                        </Box>
	                                                                    )}
	                                                                    {!startValidation && (
	                                                                        <Box>
	                                                                            {res.type ===
	                                                                                'STRING' &&
	                                                                                res.value}
	                                                                            {res.type ===
	                                                                                'CHECKBOX' && (
	                                                                                <Checkbox
	                                                                                    checked={
	                                                                                        res.value ===
	                                                                                        'TRUE'
	                                                                                            ? true
	                                                                                            : false
	                                                                                    }
	                                                                                    readOnly
	                                                                                />
	                                                                            )}
	                                                                        </Box>
	                                                                    )}
	                                                                </Grid>
	                                                                <Grid
	                                                                    item
	                                                                    xs={2}
	                                                                    key={
	                                                                        'it3e' +
	                                                                        j
	                                                                    }
	                                                                >
	                                                                    <Box>
	                                                                        <LinearProgress
	                                                                            key={
	                                                                                'circular' +
	                                                                                j
	                                                                            }
	                                                                            variant="determinate"
	                                                                            value={Math.round(
	                                                                                res.confidence *
	                                                                                    100
	                                                                            )}
	                                                                            sx={{
	                                                                                backgroundColor:
	                                                                                    '#757575',
	                                                                                height:
	                                                                                    '6px',
	                                                                            }}
	                                                                        ></LinearProgress>
	                                                                        <FormLabel>
	                                                                            {Math.round(
	                                                                                res.confidence *
	                                                                                    100
	                                                                            )}
	                                                                            %
	                                                                        </FormLabel>
	                                                                    </Box>
	                                                                </Grid>
	                                                            </Grid>
	                                                        </CardContent>
	                                                    </Card>
	                                                )
	                                            )}
	                                        </AccordionDetails>
	                                    </Accordion>
	                                </Grid>
	                            </Box>
	                        </Grid>
	                    ))}
	                {!props.editable &&
	                    formData !== null &&
	                    formData !== undefined &&
	                    formData.map((res, j) => (
	                        <Grid item xs={12} key={'unedit' + j}>
	                            <Card
	                                variant="outlined"
	                                key={'card' + j}
	                                sx={{
	                                    mt: 1,
	                                    border: !res.valid
	                                        ? '1px solid red'
	                                        : 'none',
	                                    ml: 1,
	                                    mr: 1,
	                                }}
	                                onClick={() =>
	                                    gotoPoint(res.boundingBox, res.pageNo)
	                                }
	                            >
	                                <CardHeader
	                                    key={'head' + j}
	                                    subheader={res.key}
	                                    action={
	                                        <IconButton
	                                            title="Pii Data"
	                                            sx={{
	                                                display:
	                                                    res.piiData !== null
	                                                        ? 'block'
	                                                        : 'none',
	                                            }}
	                                        >
	                                            <PersonOffOutlinedIcon />
	                                        </IconButton>
	                                    }
	                                    sx={{
	                                        p: 1,
	                                        '& .MuiCardHeader-subheader': {
	                                            color: 'darkslategray',
	                                            fontWeight: 'bold',
	                                        },
	                                    }}
	                                ></CardHeader>
	                                <CardContent
	                                    sx={{
	                                        p: 1,
	                                        pb: '8px !important',
	                                    }}
	                                    key={'content' + j}
	                                >
	                                    <Grid container key={'container' + j}>
	                                        <Grid item xs={10} key={'it2' + j}>
	                                            {res.type === 'CHECKBOX' && (
	                                                <Checkbox
	                                                    checked={
	                                                        res.value === 'TRUE'
	                                                            ? true
	                                                            : false
	                                                    }
	                                                    readOnly
	                                                />
	                                            )}
	                                            {res.type === 'STRING' && (
	                                                <Box>{res.value}</Box>
	                                            )}
	                                            {docData !== null &&
	                                                docData.status ===
	                                                    'COMPLETED' &&
	                                                res.type === 'CHECKBOX' &&
	                                                res.value !==
	                                                    (res.ocrValue.includes(
	                                                        'unselected'
	                                                    )
	                                                        ? 'FALSE'
	                                                        : 'TRUE') && (
	                                                    <Box color={'#34314c8a'}>
	                                                        <s>
	                                                            {res.ocrValue.includes(
	                                                                'unselected'
	                                                            ) ? (
	                                                                <Checkbox
	                                                                    disabled
	                                                                    icon={
	                                                                        <IndeterminateCheckBoxOutlinedIcon />
	                                                                    }
	                                                                />
	                                                            ) : (
	                                                                <Checkbox
	                                                                    disabled
	                                                                    checked
	                                                                />
	                                                            )}
	                                                        </s>
	                                                    </Box>
	                                                )}
	                                            {docData !== null &&
	                                                docData.status ===
	                                                    'COMPLETED' &&
	                                                res.type === 'STRING' &&
	                                                res.value !== res.ocrValue && (
	                                                    <Box color={'#34314c8a'}>
	                                                        <s>{res.ocrValue}</s>
	                                                    </Box>
	                                                )}
	                                        </Grid>
	                                        <Grid item xs={2} key={'it3' + j}>
	                                            {docData !== null &&
	                                                docData.status ===
	                                                    'COMPLETED' &&
	                                                res.value ===
	                                                    (res.ocrValue.includes(
	                                                        'unselected'
	                                                    )
	                                                        ? 'FALSE'
	                                                        : res.ocrValue.includes(
	                                                              'selected'
	                                                          ) &&
	                                                          res.ocrValue.indexOf(
	                                                              's'
	                                                          ) <= 1
	                                                        ? 'TRUE'
	                                                        : res.ocrValue) && (
	                                                    <Box>
	                                                        <LinearProgress
	                                                            key={'circular' + j}
	                                                            variant="determinate"
	                                                            value={Math.round(
	                                                                res.confidence *
	                                                                    100
	                                                            )}
	                                                            sx={{
	                                                                backgroundColor:
	                                                                    '#757575',
	                                                                height: '6px',
	                                                            }}
	                                                        ></LinearProgress>
	                                                        <FormLabel>
	                                                            {Math.round(
	                                                                res.confidence *
	                                                                    100
	                                                            )}
	                                                            %
	                                                        </FormLabel>
	                                                    </Box>
	                                                )}
	                                            {docData !== null &&
	                                                docData.status !==
	                                                    'COMPLETED' && (
	                                                    <Box>
	                                                        <LinearProgress
	                                                            key={'circular' + j}
	                                                            variant="determinate"
	                                                            value={Math.round(
	                                                                res.confidence *
	                                                                    100
	                                                            )}
	                                                            sx={{
	                                                                backgroundColor:
	                                                                    '#757575',
	                                                                height: '6px',
	                                                            }}
	                                                        ></LinearProgress>
	                                                        <FormLabel>
	                                                            {Math.round(
	                                                                res.confidence *
	                                                                    100
	                                                            )}
	                                                            %
	                                                        </FormLabel>
	                                                    </Box>
	                                                )}
	                                        </Grid>
	                                    </Grid>
	                                </CardContent>
	                            </Card>
	                        </Grid>
	                    ))}
	                {(docData === null ||
	                    (formData === null && invalidFormData === null)) && (
	                    <Grid
	                        item
	                        xs={12}
	                        key={'noData'}
	                        sx={{
	                            textAlign: 'center',
	                            pt: 'calc(455px /2 - 80px) !important',
	                        }}
	                    >
	                        <ArticleOutlinedIcon
	                            sx={{ fontSize: 80, color: '#dcdcdc' }}
	                        />
	                        <Typography color={'#dcdcdc'}>
	                            No Forms Available
	                        </Typography>
	                    </Grid>
	                )}
	            </Grid>

        </Box>
    )
}

export default FormData
