import React, { useEffect, useReducer, useState } from 'react'
import {
    Backdrop,
    Box,
    Breadcrumbs,
    Card,
    CircularProgress,
    Fab,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    Link,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import { getData, postData } from '../../services/Api'
import PipelineReducer from '../pipeline/create-pipeline/PipelineReducer'
import SettingsInputComponentIcon from '@mui/icons-material/SettingsInputComponent'
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import shortid from 'shortid'
import useNotification from 'app/hooks/useNotification'

const CreateConnectors = () => {
    const { createNotification } = useNotification()
    const [connectorsData, setConnectors] = useReducer(PipelineReducer, {})
    const [propertyData, setPropertyData] = useState(null)
    const [pipelineData, setPipelineData] = useState(null)
    const [intervalData, setInterval] = useState([])
    const [typeData, setType] = useState([])
    const [isFormSubmitted, formSubmitted] = useState(false)
    const [loader, setLoader] = useState(false)
    let navigate = useNavigate()
    const path = useLocation().search
    let connectorsId = ''
    if (path.includes('=')) {
        connectorsId = path.split('=')[1]
    }

    useEffect(() => {
        setLoader(true)
        getData('/core/job/config').then((data) => {
            if (data !== null) {
                setLoader(false)
                setInterval(data.intervals)
                setType(data.jobTypes)
            }
        })
    }, [])

    useEffect(() => {
        setLoader(true)
        getData('/core/pipeline').then((data) => {
            if (data !== null) {
                setLoader(false)
                if (data.content.length > 0) {
                    setPipelineData(
                        data.content.filter((val) => val.status === 'RUNNING')
                    )
                }
            }
        })
    }, [])

    useEffect(() => {
        if (connectorsId !== '') {
            setLoader(true)
            getData('/core/job/' + connectorsId).then((data) => {
                if (data !== null) {
                    setLoader(false)
                    setConnectors({ type: 'connectors', connectors: data })
                    setTimeout(() => {
                        if (
                            data.hasOwnProperty('jobProperties') &&
                            typeData.length > 0
                        ) {
                            typeData.forEach((val) => {
                                if (data.jobType === val.type) {
                                    Object.keys(data.jobProperties).forEach(
                                        (value) => {
                                            val.properties.forEach((val) => {
                                                if (val.key === value) {
                                                    val.defaultValue =
                                                        data.jobProperties[
                                                            value
                                                        ]
                                                }
                                            })
                                        }
                                    )
                                    setPropertyData(val.properties)
                                }
                            })
                        }
                    }, 100)
                }
            })
        }
    }, [connectorsId, typeData])

    const validateBasicDetails = () => {
        let flag = false
        if (
            !connectorsData.hasOwnProperty('name') ||
            connectorsData.name.trim() === ''
        ) {
            flag = true
        }
        if (
            !connectorsData.hasOwnProperty('interval') ||
            connectorsData.interval.trim() === ''
        ) {
            flag = true
        }
        if (
            !connectorsData.hasOwnProperty('pipelineId') ||
            connectorsData.pipelineId.trim() === ''
        ) {
            flag = true
        }
        if (
            !connectorsData.hasOwnProperty('jobType') ||
            connectorsData.jobType.trim() === ''
        ) {
            flag = true
        }
        if (propertyData !== null) {
            propertyData.forEach((val) => {
                if (
                    val.required &&
                    connectorsData.hasOwnProperty(val.key) &&
                    (connectorsData[val.key] === null ||
                        connectorsData[val.key].trim() === '')
                ) {
                    flag = true
                }
            })
        }
        return flag
    }

    const handleChange = (event) => {
        setConnectors({
            name: event.target.name,
            value: event.target.value,
        })
        if (event.target.name === 'pipelineId' && pipelineData !== null) {
            if (event.target.value !== '') {
                pipelineData.forEach((val) => {
                    if (val.id === event.target.value) {
                        setConnectors({
                            name: 'pipelineName',
                            value: val.name,
                        })
                    }
                })
            } else {
                setConnectors({
                    name: 'pipelineName',
                    value: null,
                })
            }
        }
        if (event.target.name === 'jobType' && typeData.length > 0) {
            if (event.target.value !== '') {
                typeData.forEach((val) => {
                    if (val.type === event.target.value) {
                        setConnectors({
                            name: 'topicName',
                            value: val.topicName,
                        })
                        setPropertyData(val.properties)
                        if (val.properties !== null) {
                            val.properties.forEach((val) => {
                                if (!connectorsData.hasOwnProperty(val.key)) {
                                    setConnectors({
                                        name: val.key,
                                        value: val.defaultValue,
                                    })
                                }
                            })
                        }
                    }
                })
            } else {
                setPropertyData(null)
            }
        }
    }

    const submitData = () => {
        formSubmitted(true)
        if (!validateBasicDetails()) {
            setLoader(true)
            if (!connectorsData.hasOwnProperty('id')) {
                const data = new Map()
                data.set('name', connectorsData.name)
                data.set('interval', connectorsData.interval)
                data.set('pipelineId', connectorsData.pipelineId)
                data.set('pipelineName', connectorsData.pipelineName)
                data.set('jobType', connectorsData.jobType)
                data.set('topicName', connectorsData.topicName)
                const propData = new Map()
                propertyData.forEach((val) => {
                    if (connectorsData.hasOwnProperty(val.key)) {
                        propData.set(val.key, connectorsData[val.key])
                    }
                })
                data.set('jobProperties', Object.fromEntries(propData))
                postData('/core/job', Object.fromEntries(data)).then(
                    (response) => {
                        setLoader(false)
                        createNotification({
                            id: shortid.generate(),
                            heading: 'Message',
                            icon: {
                                name: 'chat',
                                color: 'primary',
                            },
                            timestamp: new Date(),
                            title: 'Job Created Successfully',
                            subtitle: connectorsData.name,
                        })
                        navigate('/jobs')
                    }
                )
            } else {
                if (propertyData !== null) {
                    const propData = new Map()
                    propertyData.forEach((val) => {
                        if (connectorsData.hasOwnProperty(val.key)) {
                            propertyData.forEach((val) => {
                                if (connectorsData.hasOwnProperty(val.key)) {
                                    propData.set(
                                        val.key,
                                        connectorsData[val.key]
                                    )
                                    connectorsData['jobProperties'][val.key] =
                                        connectorsData[val.key]
                                }
                            })
                            delete connectorsData[val.key]
                        }
                    })
                }
                postData('/core/job', connectorsData).then((response) => {
                    setLoader(false)
                    createNotification({
                        id: shortid.generate(),
                        heading: 'Message',
                        icon: {
                            name: 'chat',
                            color: 'primary',
                        },
                        timestamp: new Date(),
                        title: 'Job Edited Successfully',
                        subtitle: connectorsData.name,
                    })
                    navigate('/jobs')
                })
            }
        }
    }

    return (
        <Box sx={{ ml: 2, mr: 2, mt: 2, mb: 2 }}>
            <Breadcrumbs aria-label="breadcrumb" sx={{ ml: 2, mb: 1 }}>
                <Link
                    underline="hover"
                    color="text.primary"
                    sx={{ display: 'flex', alignItems: 'center' }}
                    href={'/jobs'}
                >
                    <SettingsInputComponentIcon />
                    Jobs
                </Link>
                <Typography
                    color="text.primary"
                    sx={{ display: 'flex', alignItems: 'center' }}
                >
                    Create Job
                </Typography>
            </Breadcrumbs>
            <Card>
                <Box sx={{ m: 4 }}>
                    <Box key={'textbox'}>
                        <Grid container spacing={2}>
                            <Grid item xs={4} key={'name'}>
                                <TextField
                                    id="name"
                                    label="Job Name"
                                    name="name"
                                    error={
                                        isFormSubmitted &&
                                        (!connectorsData.hasOwnProperty(
                                            'name'
                                        ) ||
                                            connectorsData.name === '')
                                    }
                                    value={connectorsData.name || ''}
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                    autoComplete="off"
                                    size="small"
                                    helperText={
                                        isFormSubmitted &&
                                        (!connectorsData.hasOwnProperty(
                                            'name'
                                        ) ||
                                            connectorsData.name === '')
                                            ? 'Name is required'
                                            : ''
                                    }
                                    sx={{
                                        mb: 4,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4} key={'interval'}>
                                <FormControl
                                    required
                                    fullWidth
                                    size="small"
                                    key={'interval'}
                                    error={
                                        isFormSubmitted &&
                                        (!connectorsData.hasOwnProperty(
                                            'interval'
                                        ) ||
                                            connectorsData.interval === '')
                                    }
                                >
                                    <InputLabel
                                        id="interval"
                                        key={'intervalLabel'}
                                    >
                                        Interval
                                    </InputLabel>
                                    <Select
                                        labelId="interval"
                                        id="interval"
                                        name="interval"
                                        label="interval"
                                        key={'interval'}
                                        value={connectorsData.interval || ''}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={''} key={'interval-1'}>
                                            {'None'}
                                        </MenuItem>
                                        {intervalData.length > 0 &&
                                            intervalData.map((data, index) => (
                                                <MenuItem
                                                    value={data.value}
                                                    key={'interval' + index}
                                                >
                                                    {data.label}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                    {isFormSubmitted &&
                                        (!connectorsData.hasOwnProperty(
                                            'interval'
                                        ) ||
                                            connectorsData.interval === '') && (
                                            <FormHelperText>
                                                Interval is required
                                            </FormHelperText>
                                        )}
                                </FormControl>
                            </Grid>
                            <Grid item xs={4} key={'pipelineID'}>
                                <FormControl
                                    required
                                    fullWidth
                                    size="small"
                                    key={'pipelineId'}
                                    error={
                                        isFormSubmitted &&
                                        (!connectorsData.hasOwnProperty(
                                            'pipelineId'
                                        ) ||
                                            connectorsData.pipelineId === '')
                                    }
                                >
                                    <InputLabel
                                        id="pipelineId"
                                        key={'pipelineIdLabel'}
                                    >
                                        Pipeline
                                    </InputLabel>
                                    <Select
                                        labelId="pipelineId"
                                        id="pipelineId"
                                        name="pipelineId"
                                        key={'pipelineId'}
                                        label="Pipeline"
                                        value={connectorsData.pipelineId || ''}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={''} key={'pipeline-1'}>
                                            {'None'}
                                        </MenuItem>
                                        {pipelineData !== null &&
                                            pipelineData.map((data, index) => (
                                                <MenuItem
                                                    value={data.id}
                                                    key={'pipeline' + index}
                                                >
                                                    {data.name}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                    {isFormSubmitted &&
                                        (!connectorsData.hasOwnProperty(
                                            'pipelineId'
                                        ) ||
                                            connectorsData.pipelineId ===
                                                '') && (
                                            <FormHelperText>
                                                Pipeline is required
                                            </FormHelperText>
                                        )}
                                </FormControl>
                            </Grid>
                            <Grid item xs={4} key={'jobType'}>
                                <FormControl
                                    required
                                    fullWidth
                                    size="small"
                                    key={'jobType'}
                                    error={
                                        isFormSubmitted &&
                                        (!connectorsData.hasOwnProperty(
                                            'jobType'
                                        ) ||
                                            connectorsData.jobType === '')
                                    }
                                >
                                    <InputLabel id="type" key={'typeLabel'}>
                                        Job Type
                                    </InputLabel>
                                    <Select
                                        labelId="jobType"
                                        id="jobType"
                                        name="jobType"
                                        label="jobType"
                                        key={'jobType'}
                                        value={connectorsData.jobType || ''}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={''} key={'type-1'}>
                                            {'None'}
                                        </MenuItem>
                                        {typeData.map((data, index) => (
                                            <MenuItem
                                                value={data.type}
                                                key={'jobType' + index}
                                            >
                                                {data.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {isFormSubmitted &&
                                        (!connectorsData.hasOwnProperty(
                                            'jobType'
                                        ) ||
                                            connectorsData.jobType === '') && (
                                            <FormHelperText>
                                                Job Type is required
                                            </FormHelperText>
                                        )}
                                </FormControl>
                            </Grid>
                            {propertyData !== null &&
                                propertyData.map((val, index) => (
                                    <Grid item xs={4} key={'prop' + index}>
                                        <TextField
                                            id={val.key}
                                            label={val.label}
                                            name={val.key}
                                            defaultValue={
                                                val.defaultValue === null
                                                    ? ''
                                                    : val.defaultValue
                                            }
                                            onChange={handleChange}
                                            fullWidth
                                            required={val.required}
                                            autoComplete="off"
                                            helperText={
                                                isFormSubmitted &&
                                                val.required &&
                                                connectorsData.hasOwnProperty(
                                                    val.key
                                                ) &&
                                                (connectorsData[val.key] ===
                                                    null ||
                                                    connectorsData[
                                                        val.key
                                                    ].trim() === '')
                                                    ? val.label + ' is required'
                                                    : ''
                                            }
                                            error={
                                                val.required &&
                                                isFormSubmitted &&
                                                connectorsData.hasOwnProperty(
                                                    val.key
                                                ) &&
                                                (connectorsData[val.key] ===
                                                    null ||
                                                    connectorsData[
                                                        val.key
                                                    ].trim() === '')
                                            }
                                            size="small"
                                            sx={{
                                                mb: 4,
                                            }}
                                        />
                                    </Grid>
                                ))}
                        </Grid>
                    </Box>
                    <Box textAlign={'right'} marginTop={2} key={'button'}>
                        <Fab
                            color="primary"
                            variant="extended"
                            size="medium"
                            onClick={submitData}
                            sx={{
                                mr: 2,
                                marginLeft: 2,
                                backgroundColor: '#39cb7f',
                                '&:hover': {
                                    backgroundColor: '#39cb7fbf',
                                },
                                color: '#fff',
                            }}
                        >
                            <CheckIcon />
                            <Typography
                                sx={{
                                    ml: 1,
                                    textTransform: 'capitalize',
                                }}
                            >
                                Submit
                            </Typography>
                        </Fab>
                    </Box>
                </Box>
            </Card>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={loader}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    )
}

export default CreateConnectors
