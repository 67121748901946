import React from 'react'
import Search from './Search'

const SearchRoute = [
    {
        path: '/search',
        element: <Search />,
    },
]

export default SearchRoute
