import React from 'react'
import { Card, CardContent, Box, Fab, Breadcrumbs, Typography } from '@mui/material'
import Add from '@mui/icons-material/Add'
import { useNavigate } from 'react-router-dom'
import ConnectorsTable from './ConnectorsTable'
import SettingsInputComponentIcon from '@mui/icons-material/SettingsInputComponent'

const Connectors = () => {
    const navigate = useNavigate()

    const navigateToCreatePipeline = () => {
        navigate('/create-job')
    }

    return (
        <Box sx={{ ml: 2, mr: 2, mt: 2, mb: 2 }}>
            <Breadcrumbs aria-label="breadcrumb" sx={{ ml: 2, mb: 1 }}>
                <Typography
                    color="text.primary"
                    sx={{ display: 'flex', alignItems: 'center' }}
                >
                    <SettingsInputComponentIcon />
                    Jobs
                </Typography>
            </Breadcrumbs>
            <Card variant="outlined">
                <CardContent>
                    <Box>
                        <Fab
                            variant="extended"
                            size="medium"
                            color="secondary"
                            aria-label="add"
                            onClick={navigateToCreatePipeline}
                            sx={{
                                float: 'right',
                                color: '#fff',
                            }}
                            title={'Create Job'}
                        >
                            <Add sx={{ mr: 1 }} />
                            Create
                        </Fab>
                    </Box>
                    <Box
                        sx={{
                            overflow: {
                                xs: 'auto',
                                sm: 'unset',
                            },
                            mt: -5,
                        }}
                    >
                        <ConnectorsTable />
                    </Box>
                </CardContent>
            </Card>
        </Box>
    )
}

export default Connectors
