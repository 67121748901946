import React, { createContext, useEffect, useReducer } from 'react'

const reducer = (state, action) => {
    switch (action.type) {
        case 'LOAD_NOTIFICATIONS': {
            return {
                ...state,
                notifications: action.payload,
            }
        }
        case 'DELETE_NOTIFICATION': {
            return {
                ...state,
                notifications: action.payload,
            }
        }
        case 'CREATE_NOTIFICATION': {
            return {
                ...state,
                notifications: action.payload,
            }
        }
        default: {
            return { ...state }
        }
    }
}

const NotificationContext = createContext({
    notifications: [],
    deleteNotification: () => {},
    getNotifications: () => {},
    createNotification: () => {},
})

export const NotificationProvider = ({ settings, children }) => {
    const [state, dispatch] = useReducer(reducer, { notifications: [] })

    const deleteNotification = (notificationID) => {
        let temp = state.notifications.filter((v) => v.id !== notificationID)
        dispatch({
            type: 'DELETE_NOTIFICATION',
            payload: temp,
        })
    }

    const getNotifications = () => {
        dispatch({
            type: 'LOAD_NOTIFICATIONS',
            payload: state.notifications,
        })
    }
    const createNotification = (notification) => {
        dispatch({
            type: 'CREATE_NOTIFICATION',
            payload: [...state.notifications, notification],
        })
    }

    useEffect(() => {
        const getNotifications = () => {
            dispatch({
                type: 'LOAD_NOTIFICATIONS',
                payload: state.notifications,
            })
        }
        getNotifications()
    }, [state.notifications])

    return (
        <NotificationContext.Provider
            value={{
                notifications: state.notifications,
                deleteNotification,
                getNotifications,
                createNotification,
            }}
        >
            {children}
        </NotificationContext.Provider>
    )
}

export default NotificationContext
