import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'
import Pipeline from './pipeline-list/Pipeline'

const CreatePipeline = Loadable(
    lazy(() => import('./create-pipeline/CreatePipeline'))
)

const PipelineDetails = Loadable(
    lazy(() => import('./pipeline-details/PipelineDetails'))
)

const Summary = Loadable(
    lazy(() => import('./summary'))
)

const pipelineRoute = [
    {
        path: '/pipeline',
        element: <Pipeline />,
    },
    {
        path: '/create-pipeline',
        element: <CreatePipeline />,
    },
    {
        path: '/pipeline-details',
        element: <PipelineDetails />,
    },
    {
        path: '/summary',
        element: <Summary />,
    },
]

export default pipelineRoute
