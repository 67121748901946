import React from 'react'
import Connectors from './Connectors'
import CreateConnectors from './CreateConnectors'

const ConnectorsRoute = [
    {
        path: '/jobs',
        element: <Connectors />,
    },
    {
        path: '/create-job',
        element: <CreateConnectors />,
    },
]

export default ConnectorsRoute
