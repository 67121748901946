import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'

const NotFound = Loadable(lazy(() => import('./NotFound')))

const sessionRoutes = [
    {
        path: '/session/404',
        element: <NotFound />,
    },
]

export default sessionRoutes
