import { createStyles, makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            '& .MuiDataGrid-columnHeaders': {
                backgroundColor: '#f1f1f1',
            },
            '& .MuiDataGrid-row': {
                '&:nth-child(2n)': {
                    backgroundColor: '#f5f5f5',
                },
            },
        },
    })
)

export const accordianStyles = makeStyles((theme) =>
    createStyles({
        root: {
            backgroundColor: '#d7dadb52',
            color: '#666666',
            minHeight: '36px !important',
            '& .MuiAccordionSummary-content': {
                margin: '0px',
            },
        },
    })
)

export const innerAccordianStyles = makeStyles((theme) =>
    createStyles({
        root: {
            minHeight: '36px !important',
            '& .MuiAccordionSummary-content': {
                margin: '0px',
            },
        },
    })
)

export const accordianStylesNotification = makeStyles((theme) =>
    createStyles({
        root: {
            backgroundColor: '#fff',
            color: '#666666',
            minHeight: '36px !important',
            '& .MuiAccordionSummary-content': {
                margin: '0px',
            },
        },
    })
)
