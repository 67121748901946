export const config = {
    Auth: {
        region: 'ap-south-1',
        userPoolId: 'ap-south-1_6BbhggvtG',
        userPoolWebClientId: '2k23n0hft5p72cqcpqe78pnkbh',
        oauth: {
            domain: 'icx-cog-pool.auth.ap-south-1.amazoncognito.com',
            scope: ['email', 'profile', 'openid'],
            redirectSignIn: 'https://icximagingaws.com',
            redirectSignOut: 'https://icximagingaws.com',
            clientId: '2k23n0hft5p72cqcpqe78pnkbh',
            responseType: 'token',
        },
    },
}
