import React, { useState } from 'react'
import {
    Card,
    CardContent,
    Box,
    Fab,
    IconButton,
    TextField,
    Menu,
    ListItem,
    Typography,
    ToggleButtonGroup,
    ToggleButton,
    List,
    Button,
    Breadcrumbs,
} from '@mui/material'
import PipelineTable from './PipelineTable'
import Add from '@mui/icons-material/Add'
import { useNavigate } from 'react-router-dom'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined'
import PauseCircleOutlineOutlinedIcon from '@mui/icons-material/PauseCircleOutlineOutlined'
import LinearScaleIcon from '@mui/icons-material/LinearScale'

const Pipeline = () => {
    const navigate = useNavigate()
    let [menuDrawer, setMenuDrawer] = useState(null)
    let [docStatus, setDocStatus] = useState(null)
    let [pipelineName, setPipelineName] = useState(null)
    let [fromDate, setFromDate] = useState(null)
    let [toDate, setToDate] = useState(null)
    let [filterData, setFilterData] = useState(null)
    const open = Boolean(menuDrawer)
    const handleClick = (event) => {
        setMenuDrawer(event.currentTarget)
    }
    const handleClose = () => {
        setMenuDrawer(null)
    }

    const navigateToCreatePipeline = () => {
        navigate('/create-pipeline')
    }

    const handleDocStatus = (val) => {
        docStatus = val
        setDocStatus(docStatus)
    }

    const submitFilter = () => {
        const data = new Map()
        data.set('pipelineName', pipelineName)
        data.set('fromDate', fromDate)
        data.set('toDate', toDate)
        data.set('status', docStatus)
        setFilterData(Object.fromEntries(data))
    }

    const fetchRoles = () => {
        var payload = Buffer.from(
            localStorage.getItem('idToken').split('.')[1],
            'base64'
        )
        const roles = JSON.parse(payload.toString())['cognito:groups']
        return roles[0];
    }

    return (
        <Box sx={{ ml: 2, mr: 2, mt: 2, mb: 2 }}>
            <Breadcrumbs aria-label="breadcrumb" sx={{ ml: 2, mb: 1 }}>
                <Typography
                    color="text.primary"
                    sx={{ display: 'flex', alignItems: 'center' }}
                >
                    <LinearScaleIcon />
                    Pipeline
                </Typography>
            </Breadcrumbs>
            <Card variant="outlined">
                <CardContent>
                    <Box>
                        <IconButton title="Filter" onClick={handleClick}>
                            <FilterAltOutlinedIcon />
                        </IconButton>
                        <Menu
                            id="basic-menu"
                            anchorEl={menuDrawer}
                            open={open}
                            onClose={handleClose}
                        >
                            <Box>
                                <Card
                                    raised={true}
                                    sx={{
                                        m: 1,
                                        border: '1px solid grey',
                                    }}
                                >
                                    <List>
                                        <ListItem>
                                            <TextField
                                                size="small"
                                                fullWidth
                                                label="Pipeline Name"
                                                name="pipelineName"
                                                value={pipelineName || ''}
                                                onChange={(event) => {
                                                    setPipelineName(
                                                        event.target.value
                                                    )
                                                }}
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <Box>
                                                <LocalizationProvider
                                                    dateAdapter={AdapterDateFns}
                                                >
                                                    <DatePicker
                                                        label="Date From"
                                                        value={fromDate}
                                                        inputFormat="MM/dd/yyyy"
                                                        onChange={(
                                                            newValue
                                                        ) => {
                                                            let fDate = new Date(
                                                                newValue
                                                            )
                                                            setFromDate(
                                                                fDate.getMonth() +
                                                                    1 +
                                                                    '/' +
                                                                    fDate.getDate() +
                                                                    '/' +
                                                                    fDate.getFullYear()
                                                            )
                                                        }}
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <TextField
                                                                {...params}
                                                                size="small"
                                                                fullWidth
                                                                name="fromDate"
                                                            />
                                                        )}
                                                    ></DatePicker>
                                                </LocalizationProvider>
                                            </Box>
                                        </ListItem>
                                        <ListItem>
                                            <Box>
                                                <LocalizationProvider
                                                    dateAdapter={AdapterDateFns}
                                                >
                                                    <DatePicker
                                                        label="Date To"
                                                        value={toDate}
                                                        inputFormat="MM/dd/yyyy"
                                                        onChange={(
                                                            newValue
                                                        ) => {
                                                            let tDate = new Date(
                                                                newValue
                                                            )
                                                            setToDate(
                                                                tDate.getMonth() +
                                                                    1 +
                                                                    '/' +
                                                                    tDate.getDate() +
                                                                    '/' +
                                                                    tDate.getFullYear()
                                                            )
                                                        }}
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <TextField
                                                                size="small"
                                                                fullWidth
                                                                {...params}
                                                                name="toDate"
                                                            />
                                                        )}
                                                    ></DatePicker>
                                                </LocalizationProvider>
                                            </Box>
                                        </ListItem>
                                        <ListItem>
                                            <Box>
                                                <Typography
                                                    color={'#9196b1'}
                                                    fontWeight={'200'}
                                                >
                                                    Status
                                                </Typography>
                                                <ToggleButtonGroup
                                                    value={docStatus}
                                                    aria-label="Pipeline Status"
                                                    size="medium"
                                                    exclusive
                                                >
                                                    <ToggleButton
                                                        value="RUNNING"
                                                        title="Running"
                                                        sx={{
                                                            p: '13px',
                                                            color: '#2e7d32',
                                                        }}
                                                        onClick={() =>
                                                            handleDocStatus(
                                                                'RUNNING'
                                                            )
                                                        }
                                                    >
                                                        <AutorenewOutlinedIcon />
                                                        Running
                                                    </ToggleButton>
                                                    <ToggleButton
                                                        value="PAUSED"
                                                        title="Paused"
                                                        sx={{
                                                            p: '13px',
                                                            color: '#FF3D57',
                                                        }}
                                                        onClick={() =>
                                                            handleDocStatus(
                                                                'PAUSED'
                                                            )
                                                        }
                                                    >
                                                        <PauseCircleOutlineOutlinedIcon />
                                                        Paused
                                                    </ToggleButton>
                                                </ToggleButtonGroup>
                                            </Box>
                                        </ListItem>
                                        <Box sx={{ textAlign: 'right' }}>
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                sx={{ mr: 1 }}
                                                color={'inherit'}
                                                onClick={() => {
                                                    pipelineName = null
                                                    setPipelineName(
                                                        pipelineName
                                                    )
                                                    fromDate = null
                                                    setFromDate(fromDate)
                                                    toDate = null
                                                    setToDate(toDate)
                                                    handleDocStatus(null)
                                                    submitFilter()
                                                    handleClose()
                                                }}
                                            >
                                                Clear
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                sx={{ mr: 2 }}
                                                onClick={() => {
                                                    submitFilter()
                                                    handleClose()
                                                }}
                                            >
                                                Apply
                                            </Button>
                                        </Box>
                                    </List>
                                </Card>
                            </Box>
                        </Menu>
                        <Fab
                            variant="extended"
                            size="medium"
                            color="secondary"
                            aria-label="add"
                            onClick={navigateToCreatePipeline}
                            sx={{
                                float: 'right',
                                color: '#fff',
                            }}
                            disabled={
                                localStorage.getItem('accessToken') !== null &&
                                fetchRoles().includes('Reviewer')
                            }
                        >
                            <Add sx={{ mr: 1 }} />
                            Create
                        </Fab>
                    </Box>
                    <Box
                        sx={{
                            overflow: {
                                xs: 'auto',
                                sm: 'unset',
                            },
                            mt: -5,
                        }}
                    >
                        <PipelineTable getFilterData={filterData} />
                    </Box>
                </CardContent>
            </Card>
        </Box>
    )
}

export default Pipeline
