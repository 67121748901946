import { Box, FormLabel, Grid, Paper, Typography } from '@mui/material'
import React, { useEffect, useCallback, useState } from 'react'
import SentimentNeutralOutlinedIcon from '@mui/icons-material/SentimentNeutralOutlined'
import SentimentSatisfiedAltOutlinedIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined'
import SentimentDissatisfiedOutlinedIcon from '@mui/icons-material/SentimentDissatisfiedOutlined'
import { getData } from '../../services/Api'

const SentimentData = (props) => {
    const docData = props.docData
    const [sentimentData, setSentimentData] = useState(null)

    const getSentimentData = useCallback(() => {
        setSentimentData(null)
        if (docData !== null && docData.status !== 'PENDING') {
            getData(
                '/core/pipeline/' +
                    docData.pipelineId +
                    '/document/' +
                    docData.id +
                    '/sentiments'
            ).then((data) => {
                if (data !== null) {
                    setSentimentData(data)
                }
            })
        }
    }, [docData])

    useEffect(() => {
        getSentimentData()
    }, [getSentimentData])

    return (
        <Box sx={{ m: 1 }}>
            <Grid container>
                {(docData === null ||
                    sentimentData === null ||
                    sentimentData === undefined) && (
                    <Grid
                        item
                        xs={12}
                        key={'noData'}
                        sx={{
                            textAlign: 'center',
                            pt: 'calc(455px /2 - 80px) !important',
                        }}
                    >
                        <SentimentNeutralOutlinedIcon
                            sx={{ fontSize: 80, color: '#dcdcdc' }}
                        />
                        <Typography color={'#dcdcdc'}>
                            No Sentiments Available
                        </Typography>
                    </Grid>
                )}
                {docData !== null && sentimentData !== null && (
                    <Grid
                        item
                        xs={12}
                        key={'Data'}
                        sx={{
                            textAlign: 'center',
                            pt: 'calc(455px /2 - 80px) !important',
                        }}
                    >
                        {sentimentData.key === 'positive' && (
                            <Box>
                                <SentimentSatisfiedAltOutlinedIcon
                                    sx={{ fontSize: 80, color: '#91cc75' }}
                                />

                                <Box sx={{ color: '#91cc75' }}>
                                    Positive Sentiments
                                </Box>
                            </Box>
                        )}
                        {sentimentData.key === 'negative' && (
                            <Box>
                                <SentimentDissatisfiedOutlinedIcon
                                    sx={{ fontSize: 80, color: '#e8433f' }}
                                />

                                <Box sx={{ color: '#e8433f' }}>
                                    Negative Sentiments
                                </Box>
                            </Box>
                        )}
                        {sentimentData.key === 'mixed' && (
                            <Box>
                                <SentimentNeutralOutlinedIcon
                                    sx={{ fontSize: 80, color: '#ffc90a' }}
                                />

                                <Box sx={{ color: '#ffc90a' }}>
                                    Mixed Sentiments
                                </Box>
                            </Box>
                        )}
                    </Grid>
                )}
                {docData !== null && sentimentData !== null && (
                    <Grid item xs={12} key={'score'} sx={{ mt: 2 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                <Paper
                                    elevation={4}
                                    sx={{ textAlign: 'center' }}
                                >
                                    <Box>
                                        <FormLabel>Positive</FormLabel>
                                    </Box>
                                    <SentimentSatisfiedAltOutlinedIcon
                                        sx={{
                                            fontSize: '25px',
                                            mt: 1,
                                            color: '#91cc75',
                                        }}
                                    />
                                    <Box>
                                        <FormLabel>
                                            {sentimentData.positiveScore * 100}%
                                        </FormLabel>
                                    </Box>
                                </Paper>
                            </Grid>
                            <Grid item xs={4}>
                                <Paper
                                    elevation={4}
                                    sx={{ textAlign: 'center' }}
                                >
                                    <Box>
                                        <FormLabel>Neutral</FormLabel>
                                    </Box>
                                    <SentimentNeutralOutlinedIcon
                                        sx={{
                                            fontSize: '25px',
                                            mt: 1,
                                            color: '#ffc90a',
                                        }}
                                    />
                                    <Box>
                                        <FormLabel>
                                            {sentimentData.neutralScore * 100}%
                                        </FormLabel>
                                    </Box>
                                </Paper>
                            </Grid>
                            <Grid item xs={4}>
                                <Paper
                                    elevation={4}
                                    sx={{ textAlign: 'center' }}
                                >
                                    <Box>
                                        <FormLabel>Negative</FormLabel>
                                    </Box>
                                    <SentimentDissatisfiedOutlinedIcon
                                        sx={{
                                            fontSize: '25px',
                                            mt: 1,
                                            color: '#e8433f',
                                        }}
                                    />
                                    <Box>
                                        <FormLabel>
                                            {sentimentData.negativeScore * 100}%
                                        </FormLabel>
                                    </Box>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Box>
    )
}

export default SentimentData
