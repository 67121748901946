import {
    Box,
    Grid,
    Card,
    CardContent,
    CardHeader,
    LinearProgress,
    FormLabel,
    Typography,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from '@mui/material'
import React, { useEffect, useCallback, useState } from 'react'
import LanOutlinedIcon from '@mui/icons-material/LanOutlined'
import { getData } from '../../services/Api'

const EntitiesData = (props) => {
    const docData = props.docData
    const [entitiesData, setEntitiesData] = useState(null)
    const [tableData, setTableData] = useState(null)

    const getEntitiesData = useCallback(() => {
        setEntitiesData(null)
        if (docData !== null && docData.status !== 'PENDING') {
            getData(
                '/core/pipeline/' +
                    docData.pipelineId +
                    '/document/' +
                    docData.id +
                    '/entities'
            ).then((data) => {
                if (
                    data !== null &&
                    data.hasOwnProperty('KeyValuePairEntities')
                ) {
                    setEntitiesData(data.KeyValuePairEntities)
                }
                if (
                    data !== null &&
                    data.hasOwnProperty('TableEntities') &&
                    data.TableEntities.length > 0
                ) {
                    setTableData(data.TableEntities)
                }
            })
        }
    }, [docData])

    useEffect(() => {
        getEntitiesData()
    }, [getEntitiesData])

    const gotoPoint = (boundingBox, pageNo) => {
        props.drawPoint(boundingBox, pageNo)
    }
    return (
        <Box sx={{ m: 1 }}>
            <Grid container spacing={2}>
                {docData !== null && entitiesData !== null && (
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Grid container spacing={2}>
                                    {Object.keys(entitiesData).map((val, i) => (
                                        <Grid item xs={12}>
                                            <Grid
                                                container
                                                spacing={2}
                                                onClick={() =>
                                                    gotoPoint(
                                                        entitiesData[val][0]
                                                            .boundingBox,
                                                        entitiesData[val][0]
                                                            .pageNo
                                                    )
                                                }
                                            >
                                                {i === 0 && (
                                                    <Grid
                                                        item
                                                        sx={{
                                                            textAlign: 'center',
                                                        }}
                                                        xs={4}
                                                    >
                                                        <b>
                                                            <u>Key</u>
                                                        </b>
                                                    </Grid>
                                                )}
                                                {i === 0 && (
                                                    <Grid
                                                        item
                                                        sx={{
                                                            textAlign: 'center',
                                                        }}
                                                        xs={6}
                                                    >
                                                        <b>
                                                            <u>Value</u>
                                                        </b>
                                                    </Grid>
                                                )}
                                                {i === 0 && (
                                                    <Grid
                                                        item
                                                        sx={{
                                                            textAlign: 'center',
                                                        }}
                                                        xs={2}
                                                    >
                                                        <b>
                                                            <u>Confidence</u>
                                                        </b>
                                                    </Grid>
                                                )}
                                                <Grid
                                                    item
                                                    xs={4}
                                                    sx={{
                                                        overflow: 'hidden',
                                                        textOverflow:
                                                            'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                        borderRight:
                                                            '1px solid grey',
                                                        borderTop:
                                                            '1px solid grey',
                                                    }}
                                                >
                                                    <b>{val}</b>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sx={{
                                                        borderRight:
                                                            '1px solid grey',
                                                        borderTop:
                                                            '1px solid grey',
                                                    }}
                                                >
                                                    {entitiesData[val][0].text}
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={2}
                                                    sx={{
                                                        pr: 2,
                                                        borderTop:
                                                            '1px solid grey',
                                                    }}
                                                >
                                                    <Box>
                                                        <LinearProgress
                                                            key={'circular' + i}
                                                            variant="determinate"
                                                            value={Math.round(
                                                                entitiesData[
                                                                    val
                                                                ][0]
                                                                    .confidence *
                                                                    100
                                                            )}
                                                            sx={{
                                                                backgroundColor:
                                                                    '#757575',
                                                                height: '6px',
                                                            }}
                                                        ></LinearProgress>
                                                        <FormLabel>
                                                            {Math.round(
                                                                entitiesData[
                                                                    val
                                                                ][0]
                                                                    .confidence *
                                                                    100
                                                            )}
                                                            %
                                                        </FormLabel>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                )}
                {docData !== null &&
                    tableData !== null &&
                    tableData.length > 0 &&
                    tableData.map((val, i) => (
                        <Grid
                            item
                            key={'tab' + i}
                            xs={12}
                            sx={{
                                display:
                                    val.headers !== null &&
                                    val.headers.length > 0
                                        ? 'block'
                                        : 'none',
                            }}
                        >
                            <Box key={'tabBox' + i}>
                                <Card
                                    variant="outlined"
                                    key={'tabCard' + i}
                                    sx={{ mt: 1, overflow: 'auto' }}
                                >
                                    <CardContent key={'content' + i}>
                                        <Table
                                            sx={{ minWidth: 650 }}
                                            key={'table' + i}
                                        >
                                            <TableHead key={'head' + i}>
                                                <TableRow key={'row' + i}>
                                                    {val.hasOwnProperty(
                                                        'headers'
                                                    ) &&
                                                        val.headers !== null &&
                                                        val.headers.length >
                                                            0 &&
                                                        val.headers.map(
                                                            (hdr) => (
                                                                <TableCell align="center">
                                                                    {hdr}
                                                                </TableCell>
                                                            )
                                                        )}
                                                    {val.hasOwnProperty(
                                                        'headers'
                                                    ) &&
                                                        val.headers ===
                                                            null && (
                                                            <Box>
                                                                Table not
                                                                Extracted
                                                            </Box>
                                                        )}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {val.hasOwnProperty('rows') &&
                                                    val.rows !== null &&
                                                    val.rows.length > 0 &&
                                                    val.rows.map((r) => (
                                                        <TableRow>
                                                            {r.hasOwnProperty(
                                                                'cells'
                                                            ) &&
                                                                r.cells !==
                                                                    null &&
                                                                r.cells.length >
                                                                    0 &&
                                                                r.cells.map(
                                                                    (c) => (
                                                                        <TableCell align="center">
                                                                            {c.text ===
                                                                            null
                                                                                ? ''
                                                                                : c.text}
                                                                        </TableCell>
                                                                    )
                                                                )}
                                                        </TableRow>
                                                    ))}
                                            </TableBody>
                                        </Table>
                                    </CardContent>
                                </Card>
                            </Box>
                        </Grid>
                    ))}
                {(docData === null ||
                    docData === undefined ||
                    entitiesData === null ||
                    entitiesData === undefined) && (
                    <Grid
                        item
                        xs={12}
                        key={'noData'}
                        sx={{
                            textAlign: 'center',
                            pt: 'calc(455px /2 - 80px) !important',
                        }}
                    >
                        <LanOutlinedIcon
                            sx={{ fontSize: 80, color: '#dcdcdc' }}
                        />
                        <Typography color={'#dcdcdc'}>
                            No Entities Available
                        </Typography>
                    </Grid>
                )}
            </Grid>
        </Box>
    )
}

export default EntitiesData
