import useAuth from 'app/hooks/useAuth'
import { flat } from 'app/utils/utils'
import React, { useState, useEffect } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { AllPages } from '../routes/routes'
import { Auth, Amplify } from 'aws-amplify'
import { config } from 'app/views/sessions/login/CognitoConfig'

Amplify.configure(config)

const AuthGuard = ({ children }) => {
    const { isAuthenticated, user } = useAuth()
    const { login } = useAuth()

    const handleLogin = () => {
        Auth.currentAuthenticatedUser()
            .then((user) => {
                console.log(localStorage.getItem('loginStatus'))
                if (user.hasOwnProperty('signInUserSession')) {
                    localStorage.setItem(
                        'email',
                        user['signInUserSession']['idToken']['payload']['email']
                    )
                    localStorage.setItem(
                        'name',
                        user['signInUserSession']['idToken']['payload'][
                            'given_name'
                        ]
                    )
                    localStorage.setItem(
                        'accessToken',
                        user['signInUserSession']['idToken']['jwtToken']
                    )
                    localStorage.setItem(
                        'idToken',
                        user['signInUserSession']['accessToken']['jwtToken']
                    )
                    localStorage.setItem('imageUrl', null)
                    localStorage.setItem(
                        'expiresOn',
                        new Date(
                            new Date(
                                user['signInUserSession']['idToken']['payload'][
                                    'exp'
                                ]
                            )
                        )
                    )
                    const roles =
                        user['signInUserSession']['idToken']['payload'][
                            'cognito:groups'
                        ]
                    localStorage.setItem('roles', roles[0])
                    login()
                }
            })
            .catch((err) => {
                const user = Auth.federatedSignIn()
                localStorage.setItem('loginStatus', true)
                if (user.hasOwnProperty('signInUserSession')) {
                    localStorage.setItem(
                        'email',
                        user['signInUserSession']['idToken']['payload']['email']
                    )
                    localStorage.setItem(
                        'name',
                        user['signInUserSession']['idToken']['payload'][
                            'given_name'
                        ]
                    )
                    localStorage.setItem(
                        'accessToken',
                        user['signInUserSession']['idToken']['jwtToken']
                    )
                    localStorage.setItem(
                        'idToken',
                        user['signInUserSession']['accessToken']['jwtToken']
                    )
                    localStorage.setItem('imageUrl', null)
                    localStorage.setItem(
                        'expiresOn',
                        new Date(
                            new Date(
                                user['signInUserSession']['idToken']['payload'][
                                    'exp'
                                ]
                            )
                        )
                    )
                    const roles =
                        user['signInUserSession']['idToken']['payload'][
                            'cognito:groups'
                        ]
                    localStorage.setItem('roles', roles[0])
                    login()
                }
            })
    }

    const fetchRoles = () => {
        var payload = Buffer.from(
            localStorage.getItem('idToken').split('.')[1],
            'base64'
        )
        const roles = JSON.parse(payload.toString())['cognito:groups']
        return roles[0]
    }

    const getUserRoleAuthStatus = (pathname, user, routes) => {
        let authenticated = false
        if (!user) {
            handleLogin()
            return
        }
        if (localStorage.getItem('loginStatus') !== 'false') {
            console.log(user)
            const matched = routes.find((r) => r.path === pathname)
            if (
                localStorage.getItem('accessToken') !== null &&
                fetchRoles().includes('Reviewer')
            ) {
                if (
                    matched.path === '/inbox' ||
                    matched.path === '/pipeline' ||
                    matched.path === '/pipeline-details' ||
                    matched.path === '/summary' ||
                    matched.path === '/dashboard'
                ) {
                    authenticated = true
                }
            }
            if (
                localStorage.getItem('accessToken') !== null &&
                fetchRoles().includes('PipelineAdmin')
            ) {
                if (
                    matched.path === '/inbox' ||
                    matched.path === '/pipeline' ||
                    matched.path === '/create-pipeline' ||
                    matched.path === '/pipeline-details' ||
                    matched.path === '/summary' ||
                    matched.path === '/dashboard'
                ) {
                    authenticated = true
                }
            }
            if (
                localStorage.getItem('accessToken') !== null &&
                fetchRoles().includes('SysAdmin')
            ) {
                authenticated = true
            }
        } else {
            localStorage.clear()
            window.location.reload()
            return
        }
        return authenticated
    }

    // return <>{isAuthenticated ? children : <Navigate to="/session/signin" />}</>

    const [previouseRoute, setPreviousRoute] = useState(null)
    const { pathname } = useLocation()
    const routes = flat(AllPages())

    const isUserRoleAuthenticated = getUserRoleAuthStatus(
        pathname,
        user,
        routes
    )
    let authenticated = isAuthenticated && isUserRoleAuthenticated

    // IF YOU NEED ROLE BASED AUTHENTICATION,
    // UNCOMMENT ABOVE TWO LINES, getUserRoleAuthStatus METHOD AND user VARIABLE
    // AND COMMENT OUT BELOW LINE

    // let authenticated = isAuthenticated

    useEffect(() => {
        if (previouseRoute !== null) setPreviousRoute(pathname)
    }, [pathname, previouseRoute])
    if (authenticated) {
        return <>{children}</>
    } else if (
        localStorage.getItem('accessToken') !== null &&
        localStorage.getItem('accessToken') !== undefined
    ) {
        return (
            <Navigate
                to="/session/404"
                state={{ redirectUrl: previouseRoute }}
            />
        )
    } else {
        return null
    }
}

export default AuthGuard
