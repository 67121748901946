import React, { useState, useEffect, useCallback } from 'react'
import {
    Box,
    IconButton,
    Switch,
    Chip,
    Backdrop,
    CircularProgress,
} from '@mui/material'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteOutline from '@mui/icons-material/DeleteOutline'
import VisibilityIcon from '@mui/icons-material/Visibility'
import Alert from '../../components/Dialog/Alert'
import { getData, putData, deleteData } from '../../services/Api'
import { DataGrid } from '@mui/x-data-grid'
import Moment from 'react-moment'
import RenderCellExpand from 'app/components/RenderCellExpand'
import { useStyles } from '../pipeline/TableTheme'
import InfoAlert from '../../components/Dialog/InfoAlert'
import { useNavigate } from 'react-router-dom'
import ConnectorsDetail from './ConnectorsDetail'
import CachedIcon from '@mui/icons-material/Cached'

const ConnectorsTable = (props) => {
    const [connectorsList, updateList] = useState([])
    const [page, setPage] = React.useState(0)
    const [totalElements, setTotalElements] = React.useState(0)
    const [openAlertValue, setAlertValue] = useState(false)
    const [openInfoAlertValue, setInfoAlertValue] = useState(false)
    let [yesButtonStatus, setButtonStatus] = useState(false)
    let [indexValue, updateIndexValue] = useState()
    let [infoTitle, setInfoTitle] = useState()
    const cssClass = useStyles()
    const [loader, setLoader] = useState(false)
    let navigate = useNavigate()
    const [openDialog, setOpen] = useState(false)
    const [jobInstances, setJobInstances] = useState(null)

    const setDialog = (value) => {
        setOpen(false)
    }

    const connectorsColumns = [
        {
            field: 'name',
            headerName: 'Job Name',
            minWidth: 200,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            renderCell: RenderCellExpand,
        },
        {
            field: 'pipelineName',
            headerName: 'Pipeline Name',
            minWidth: 200,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            renderCell: RenderCellExpand,
        },
        {
            field: 'jobType',
            headerName: 'Type',
            minWidth: 180,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            disableColumnMenu: true,
            renderCell: (val) => {
                return val.row.jobType === 'EMAIL_LISTENER'
                    ? 'Email Listener'
                    : val.row.jobType
            },
        },
        {
            field: 'interval',
            headerName: 'Interval',
            minWidth: 80,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            disableColumnMenu: true,
            renderCell: (val) => {
                let value = val.row.interval
                let inter = 'N/A'
                inter =
                    value.substring(2, value.length - 1) +
                    (value.substring(value.length - 1, value.length) === 'S'
                        ? ' Second'
                        : value.substring(value.length - 1, value.length) ===
                          'M'
                        ? ' Minute'
                        : ' Hour')
                return inter
            },
        },
        {
            field: 'lastCompletdOn',
            headerName: 'Last Completed',
            minWidth: 200,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            disableColumnMenu: true,
            renderCell: (val) => {
                return val.row.lastCompletdOn === null ? (
                    'N/A'
                ) : (
                    <Moment format="DD/MM/YYYY - HH:mm">
                        {val.row.lastCompletdOn}
                    </Moment>
                )
            },
        },
        {
            field: 'status',
            headerName: 'Status',
            minWidth: 150,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            disableColumnMenu: true,
            renderCell: (val) => {
                return (
                    <Chip
                        sx={{
                            pl: '4px',
                            pr: '4px',
                            color: '#fff',
                            backgroundColor:
                                val.row.status === 'CREATED'
                                    ? 'primary.main'
                                    : val.row.status === 'STOPPED' ||
                                      val.row.status === 'FAILED'
                                    ? '#FF3D57'
                                    : val.row.status === 'STARTING' ||
                                      val.row.status === 'COMPLETED' ||
                                      val.row.status === 'RUNNING'
                                    ? 'success.main'
                                    : '',
                            minWidth: '90px',
                        }}
                        size="small"
                        label={val.row.status}
                        icon={
                            <CachedIcon
                                sx={{
                                    display:
                                        val.row.status === 'STARTING'
                                            ? 'block'
                                            : 'none',
                                    animation: 'icon-spin infinite 2s linear',
                                    color: '#fff !important',
                                }}
                            />
                        }
                    ></Chip>
                )
            },
        },
        {
            field: 'action',
            headerName: 'Actions',
            minWidth: 180,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            align: 'center',
            renderCell: (val, index) => {
                return (
                    <Box>
                        <IconButton
                            color="primary"
                            aria-label="Job Details"
                            title="Job Details"
                            onClick={() => {
                                setOpen(true)
                                setJobInstances(val.row.jobInstances)
                            }}
                            disabled={val.row.status === 'CREATED'}
                        >
                            <VisibilityIcon />
                        </IconButton>
                        <IconButton
                            color="warning"
                            aria-label="Edit"
                            title="Edit Job"
                            onClick={() => gotoEdit(val)}
                        >
                            <EditOutlinedIcon />
                        </IconButton>

                        <Switch
                            title={
                                val.row.status === 'COMPLETED' ||
                                val.row.status === 'STOPPED'
                                    ? 'Start Job'
                                    : 'Stop Job'
                            }
                            color="primary"
                            aria-label="Job"
                            defaultChecked={
                                val.row.status === 'CREATED' ||
                                val.row.status === 'STOPPED' ||
                                val.row.status === 'FAILED'
                                    ? false
                                    : true
                            }
                            onChange={toggleChecked(val)}
                        ></Switch>
                        <IconButton
                            color="error"
                            aria-label="Delete"
                            title="Delete Job"
                            onClick={() => openAlert(val)}
                        >
                            <DeleteOutline />
                        </IconButton>
                    </Box>
                )
            },
        },
    ]

    const setAlertDialog = (value) => {
        setAlertValue(value)
    }

    const setInfoAlertDialog = (value) => {
        setInfoAlertValue(value)
    }

    const getIndex = (val) => {
        let rowNum = -1
        connectorsList.forEach((value, index) => {
            if (val.id === value.id) {
                rowNum = index
            }
        })
        return rowNum
    }

    const openAlert = (val) => {
        if (val.row.status === 'STARTING' || val.row.status === 'COMPLETED') {
            setInfoTitle("Pipeline is Running! You can't Delete.")
            openInfoAlert(val)
        } else {
            setAlertValue(true)
            updateIndexValue(getIndex(val))
        }
    }

    const gotoEdit = (val) => {
        if (val.row.status === 'STARTING' || val.row.status === 'COMPLETED') {
            setInfoTitle("Pipeline is Running! You can't Edit.")
            openInfoAlert(val)
        } else {
            navigate('/create-job?connectors-id=' + val.id)
        }
    }

    const openInfoAlert = (val) => {
        setInfoAlertValue(true)
    }

    const setYesButtonStatus = (value) => {
        setButtonStatus(value)
        deletePipelinData(connectorsList[indexValue])
    }

    useEffect(() => {
        if (yesButtonStatus) {
            setButtonStatus(false)
        }
    }, [yesButtonStatus, indexValue])

    const getConnectorsData = useCallback(() => {
        let url = '/core/job/?page=' + page
        getData(url).then((data) => {
            if (data !== null) {
                let flag = false
                updateList(data.content)
                setTotalElements(data.totalElements)
                if (data.content.length > 0) {
                    data.content.forEach((val) => {
                        if (val.status === 'STARTING') {
                            flag = true
                        }
                    })
                }
                if (flag) {
                    flag = false
                    setTimeout(getConnectorsData, 5000)
                }
            }
        })
    }, [page])

    const deletePipelinData = (value) => {
        setLoader(true)
        deleteData('/core/job/' + value.id).then((data) => {
            getConnectorsData()
            setLoader(false)
        })
    }

    useEffect(() => {
        getConnectorsData()
    }, [getConnectorsData])

    const handleChangePage = (newPage) => {
        setPage(newPage)
    }

    const toggleChecked = (value) => (event) => {
        if (event.target.checked) {
            setLoader(true)
            putData('/core/job/' + value.id + '/start').then((data) => {
                getConnectorsData()
                setLoader(false)
            })
        } else if (!event.target.checked) {
            setLoader(true)
            putData('/core/job/' + value.id + '/stop').then((data) => {
                getConnectorsData()
                setLoader(false)
            })
        }
    }

    return (
        <div style={{ height: 415, width: '100%', marginTop: '90px' }}>
            <DataGrid
                rows={connectorsList}
                columns={connectorsColumns}
                pageSize={10}
                onPageChange={(page) => handleChangePage(page)}
                pagination
                rowCount={totalElements}
                rowHeight={32}
                headerHeight={40}
                disableSelectionOnClick={true}
                className={cssClass.root}
                resizable={true}
                showCellRightBorder={true}
                showColumnRightBorder={true}
                paginationMode="server"
            />
            <Alert
                value={openAlertValue}
                updateAlertDialog={setAlertDialog}
                updateYesButtonStatus={setYesButtonStatus}
            />
            <InfoAlert
                value={openInfoAlertValue}
                updateInfoAlertDialog={setInfoAlertDialog}
                infoTitle={infoTitle}
            />
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={loader}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <ConnectorsDetail
                updateDialog={setDialog}
                value={openDialog}
                jobInstancesData={jobInstances}
            />
        </div>
    )
}

export default ConnectorsTable
