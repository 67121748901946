import React, { useEffect } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import { Typography, Fab, DialogTitle, Grid } from '@mui/material'
import DeleteOutline from '@mui/icons-material/DeleteOutline'

const Alert = (prop) => {
    const [openAlert, setOpen] = React.useState(prop.value)

    const handleClose = () => {
        setOpen(false)
        prop.updateAlertDialog(false)
    }

    const handleYesButton = () => {
        prop.updateYesButtonStatus(true)
        handleClose()
    }

    useEffect(() => {
        setOpen(prop.value)
    }, [prop.value])

    return (
        <div>
            <Dialog open={openAlert} onClose={handleClose} fullWidth maxWidth={'xs'}>
                <DialogTitle>
                    <Grid container>
                        <Grid item sx={{ mt: 0.5 }}>
                            <DeleteOutline />
                        </Grid>
                        <Grid item>
                            <b>Delete</b>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Typography component={'span'} color="#000">
                            Are you sure want to delete ?
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Fab
                        variant="extended"
                        size="medium"
                        color="primary"
                        type="submit"
                        onClick={handleYesButton}
                        sx={{
                            ml: 2,
                            mb: 2,
                            minWidth : 100,
                            backgroundColor: '#38c1c9',
                            '&:hover': {
                                backgroundColor: '#38c1c9bf',
                            },
                        }}
                    >
                        <Typography
                            sx={{
                                textTransform: 'capitalize',
                            }}
                        >
                            Yes
                        </Typography>
                    </Fab>
                    <Fab
                        color="primary"
                        size="medium"
                        variant="extended"
                        onClick={handleClose}
                        sx={{
                            ml: 2,
                            mb: 2,
                            minWidth : 100,
                            backgroundColor: '#e45a68',
                            '&:hover': {
                                backgroundColor: '#e45a68bf',
                            },
                        }}
                    >
                        <Typography
                            sx={{
                                textTransform: 'capitalize',
                            }}
                        >
                            No
                        </Typography>
                    </Fab>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default Alert
