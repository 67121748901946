import {
    Backdrop,
    Box,
    Breadcrumbs,
    Card,
    CardContent,
    CircularProgress,
    Typography,
    Snackbar,
    Alert,
    Link,
} from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import ListViewer from 'app/views/list-viewer/ListViewer'
import { getData } from '../../services/Api'
import InboxIcon from '@mui/icons-material/Inbox'
import { ArrowForward } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

const Inbox = () => {
    let [documentList, setDocumentList] = useState([])
    let [currentListPage, setCurrentListPage] = useState(0)
    let [pipelineName, setPipelineName] = useState('')
    let [documentName, setDocumentName] = useState('')
    let [fromDate, setFromDate] = useState('')
    let [toDate, setToDate] = useState('')
    const [loader, setLoader] = useState(false)
    const [open, setOpen] = useState(false)
    const [severity, setSeverity] = useState('success')
    const [message, setMessage] = useState('')
    const [link, setLink] = useState('')
    const horizontal = 'right'
    const vertical = 'top'
    const [document, setDocument] = useState(null)
    let navigate = useNavigate()
    const getDataList = useCallback(() => {
        setLoader(true)
        let url = '/core/inbox/documents?page=' + currentListPage
        if (pipelineName !== '' && pipelineName !== null) {
            url = url + '&pipelineName=' + pipelineName
        }
        if (documentName !== '' && documentName !== null) {
            url = url + '&documentName=' + documentName
        }
        if (
            fromDate !== '' &&
            fromDate !== null &&
            toDate !== '' &&
            toDate !== null
        ) {
            let fDate = new Date(fromDate)
            let tDate = new Date(toDate)
            url =
                url +
                '&fromDate=' +
                (fDate.getMonth() + 1) +
                '/' +
                fDate.getDate() +
                '/' +
                fDate.getFullYear() +
                '&toDate=' +
                (tDate.getMonth() + 1) +
                '/' +
                tDate.getDate() +
                '/' +
                tDate.getFullYear()
        }
        getData(url).then((data) => {
            let counter = 0
            data.content.map((val, index) => {
                return getData('/core/pipeline/' + val.pipelineId).then(
                    (res) => {
                        ++counter
                        val.pipeline = res
                    }
                )
            })
            const interval = setInterval(() => {
                console.log(counter)
                if (counter === data.content.length) {
                    setLoader(false)
                    setDocumentList(data)
                    breakInterval()
                }
            }, 500)
            const breakInterval = () => {
                clearInterval(interval)
            }
        })
    }, [currentListPage, pipelineName, documentName, fromDate, toDate])

    useEffect(() => {
        getDataList()
    }, [getDataList])

    const updatePageList = (val) => {
        setCurrentListPage(val)
    }

    const updateLoader = (val) => {
        setLoader(val)
    }

    const refreshDocument = () => {
        getDataList()
    }

    const handleClose = () => {
        setOpen(false)
    }

    const getFilterData = (val) => {
        if (val.hasOwnProperty('pipelineName')) {
            setPipelineName(val.pipelineName)
        }
        if (val.hasOwnProperty('documentName')) {
            setDocumentName(val.documentName)
        }
        if (val.hasOwnProperty('fromDate')) {
            setFromDate(val.fromDate)
        }
        if (val.hasOwnProperty('toDate')) {
            setToDate(val.toDate)
        }
    }

    const getUploadedData = (val) => {
        setSeverity(val.severity)
        setOpen(val.open)
        setMessage(val.message)
        setLink(val.link)
    }

    const selectedDoc = (val) => {
        setDocument(val)
    }

    return (
        <Box sx={{ m: 2 }}>
            <Breadcrumbs aria-label="breadcrumb" sx={{ ml: 2, mb: 1 }}>
                <Typography
                    color="text.primary"
                    sx={{ display: 'flex', alignItems: 'center' }}
                >
                    <InboxIcon />
                    <Box>Inbox</Box>
                    {document !== null && (
                        <Box sx={{ ml: 1 }}>
                            <ArrowForward sx={{ fontSize: '14px', mt: 0.5 }} />
                        </Box>
                    )}
                    {document !== null && (
                        <Box sx={{ ml: 1 }}>{document.name}</Box>
                    )}
                </Typography>
            </Breadcrumbs>
            <Card variant="outlined">
                <CardContent>
                    <div>
                        <ListViewer
                            setLoader={updateLoader}
                            docList={documentList}
                            updatePage={updatePageList}
                            showUploadIcon={false}
                            reloadDoc={refreshDocument}
                            editable={true}
                            showPipelineName={true}
                            updateFilterData={getFilterData}
                            uploadedData={getUploadedData}
                            selectedDocument={selectedDoc}
                        />
                    </div>
                </CardContent>
            </Card>
            <Snackbar
                open={open}
                autoHideDuration={15000}
                onClose={handleClose}
                anchorOrigin={{ vertical, horizontal }}
            >
                <Alert
                    onClose={handleClose}
                    severity={severity}
                    color={severity}
                    sx={{ width: '100%' }}
                >
                    {message}
                    <br />
                    {link !== undefined && (
                        <Link
                            onClick={() => {
                                navigate(
                                    '/pipeline-details?pipline-id=' +
                                        link.pipelineId
                                )
                            }}
                        >
                            {link.pipelineName}
                        </Link>
                    )}
                </Alert>
            </Snackbar>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={loader}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    )
}

export default Inbox
