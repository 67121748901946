import React, { useEffect, useState } from 'react'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Card,
    Dialog,
    DialogContent,
    DialogTitle,
    FormLabel,
    Grid,
    ListItem,
    Stack,
    Typography,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { accordianStyles } from '../pipeline/TableTheme'
import Moment from 'react-moment'

const ConnectorsDetail = (props) => {
    const [openModal, setOpen] = useState(props.value)
    const [messageRead, setMessageRead] = useState(0)
    const [fileUploaded, setFileUploaded] = useState(0)
    const [failed, setFailed] = useState(0)
    const [success, setSuccess] = useState(0)
    const cssClass = accordianStyles()
    let jobInstances =
        props.jobInstancesData === null ? null : props.jobInstancesData

    useEffect(() => {
        if (jobInstances !== null) {
            let vFailed = 0
            let vSuccess = 0
            let vMessageRead = 0
            let vFileUploaded = 0
            jobInstances.forEach((val) => {
                if (val.Status === 'Failed') {
                    vFailed = vFailed + 1
                } else {
                    vSuccess = vSuccess + 1
                    vMessageRead = vMessageRead + val['Total Messages Read']
                    vFileUploaded =
                        vFileUploaded + val['Total Attachment File Uploaded']
                }
            })
            setFailed(vFailed)
            setSuccess(vSuccess)
            setMessageRead(vMessageRead)
            setFileUploaded(vFileUploaded)
        }
    }, [jobInstances])

    const handleClose = () => {
        setOpen(false)
        props.updateDialog(false)
        jobInstances = null
    }

    useEffect(() => {
        setOpen(props.value)
    }, [props.value])

    return (
        <Box>
            <Dialog
                open={openModal}
                onClose={handleClose}
                sx={{
                    '& .MuiDialog-paper': {
                        minWidth: '40%',
                        maxWidth: '50%',
                        borderRadius: 2,
                        minHeight: '60%',
                        maxHeight: '80%',
                    },
                }}
                maxWidth="md"
            >
                <DialogTitle>
                    <Typography variant="h6">Job Details</Typography>
                </DialogTitle>
                <DialogContent>
                    <Box>
                        <Box>
                            <Stack
                                direction={'row'}
                                justifyContent="space-between"
                                alignItems="center"
                                spacing={2}
                            >
                                <Card variant="outlined">
                                    <Box
                                        sx={{
                                            textAlign: '-webkit-center',
                                            m: 2,
                                            minWidth: 100,
                                        }}
                                    >
                                        <Typography variant="h6" sx={{ mt: 1 }}>
                                            {' '}
                                            {fileUploaded}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                color: 'rgb(117, 117, 117)',
                                            }}
                                        >
                                            Files Uploaded
                                        </Typography>
                                    </Box>
                                </Card>
                                <Card variant="outlined">
                                    <Box
                                        sx={{
                                            textAlign: '-webkit-center',
                                            m: 2,
                                            minWidth: 100,
                                        }}
                                    >
                                        <Typography variant="h6" sx={{ mt: 1 }}>
                                            {' '}
                                            {messageRead}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                color: 'rgb(117, 117, 117)',
                                            }}
                                        >
                                            Messages Read
                                        </Typography>
                                    </Box>
                                </Card>
                                <Card variant="outlined">
                                    <Box
                                        sx={{
                                            textAlign: '-webkit-center',
                                            m: 2,
                                            minWidth: 100,
                                        }}
                                    >
                                        <Typography variant="h6" sx={{ mt: 1 }}>
                                            {' '}
                                            {success}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                color: 'rgb(117, 117, 117)',
                                            }}
                                        >
                                            Success
                                        </Typography>
                                    </Box>
                                </Card>
                                <Card variant="outlined">
                                    <Box
                                        sx={{
                                            textAlign: '-webkit-center',
                                            m: 2,
                                            minWidth: 100,
                                        }}
                                    >
                                        <Typography variant="h6" sx={{ mt: 1 }}>
                                            {' '}
                                            {failed}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                color: 'rgb(117, 117, 117)',
                                            }}
                                        >
                                            Failed
                                        </Typography>
                                    </Box>
                                </Card>
                            </Stack>
                        </Box>
                        <Box
                            sx={{
                                mt: 2,
                                overflowY: 'auto',
                                maxHeight: '320px',
                            }}
                        >
                            {jobInstances !== null &&
                                jobInstances.map((val, index) => (
                                    <Accordion
                                        disableGutters={true}
                                        key={'acc' + index}
                                    >
                                        <AccordionSummary
                                            className={cssClass.root}
                                            expandIcon={<ExpandMoreIcon />}
                                            key={'Summ' + index}
                                        >
                                            {'Last Completed - '}
                                            {
                                                <Moment format="DD/MM/YYYY - HH:mm">
                                                    {val['Completed On']}
                                                </Moment>
                                            }
                                            <Box
                                                sx={{
                                                    color:
                                                        val.Status === 'Failed'
                                                            ? 'red'
                                                            : 'green',
                                                    pl: 2,
                                                }}
                                            >
                                                <b>{val.Status}</b>
                                            </Box>
                                        </AccordionSummary>
                                        <AccordionDetails key={'det' + index}>
                                            <Grid container spacing={2}>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    key={'it' + index}
                                                    sx={{
                                                        pl: '4px !important',
                                                        pt: '16px !important',
                                                    }}
                                                >
                                                    <ListItem
                                                        sx={{
                                                            pt:
                                                                '4px !important',
                                                            pb:
                                                                '4px !important',
                                                            pl:
                                                                '4px !important',
                                                        }}
                                                    >
                                                        <FormLabel
                                                            key={
                                                                'started' +
                                                                index
                                                            }
                                                            sx={{
                                                                color:
                                                                    '#949db2',
                                                                pr: 1,
                                                            }}
                                                        >
                                                            Job Started:
                                                        </FormLabel>
                                                        <Typography>
                                                            <Moment format="DD/MM/YYYY - HH:mm">
                                                                {
                                                                    val[
                                                                        'Started On'
                                                                    ]
                                                                }
                                                            </Moment>
                                                        </Typography>
                                                    </ListItem>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    key={'it1' + index}
                                                    sx={{
                                                        pl: '4px !important',
                                                        pt: '4px !important',
                                                    }}
                                                >
                                                    <ListItem
                                                        sx={{
                                                            pt:
                                                                '4px !important',
                                                            pb:
                                                                '4px !important',
                                                            pl:
                                                                '4px !important',
                                                        }}
                                                    >
                                                        <FormLabel
                                                            key={
                                                                'completed' +
                                                                index
                                                            }
                                                            sx={{
                                                                color:
                                                                    '#949db2',
                                                                pr: 1,
                                                            }}
                                                        >
                                                            Job Completed:
                                                        </FormLabel>
                                                        <Typography>
                                                            {
                                                                <Moment format="DD/MM/YYYY - HH:mm">
                                                                    {
                                                                        val[
                                                                            'Completed On'
                                                                        ]
                                                                    }
                                                                </Moment>
                                                            }
                                                        </Typography>
                                                    </ListItem>
                                                </Grid>
                                                {val.Status === 'Failed' && (
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        key={'it3' + index}
                                                        sx={{
                                                            pl:
                                                                '4px !important',
                                                            pt:
                                                                '4px !important',
                                                        }}
                                                    >
                                                        <ListItem
                                                            sx={{
                                                                pt:
                                                                    '4px !important',
                                                                pb:
                                                                    '4px !important',
                                                                pl:
                                                                    '4px !important',
                                                                overflow:
                                                                    'hidden',
                                                            }}
                                                        >
                                                            <FormLabel
                                                                key={
                                                                    'message' +
                                                                    index
                                                                }
                                                                sx={{
                                                                    color:
                                                                        '#949db2',
                                                                    pr: 1,
                                                                }}
                                                            >
                                                                Error Message:
                                                            </FormLabel>
                                                            <Typography>
                                                                {
                                                                    val[
                                                                        'Error Message'
                                                                    ]
                                                                }
                                                            </Typography>
                                                        </ListItem>
                                                    </Grid>
                                                )}
                                                {val.Status === 'Success' && (
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        key={'it4' + index}
                                                        sx={{
                                                            pl:
                                                                '4px !important',
                                                            pt:
                                                                '4px !important',
                                                        }}
                                                    >
                                                        <ListItem
                                                            sx={{
                                                                pt:
                                                                    '4px !important',
                                                                pb:
                                                                    '4px !important',
                                                                pl:
                                                                    '4px !important',
                                                            }}
                                                        >
                                                            <FormLabel
                                                                key={
                                                                    'upload' +
                                                                    index
                                                                }
                                                                sx={{
                                                                    color:
                                                                        '#949db2',
                                                                    pr: 1,
                                                                }}
                                                            >
                                                                Total Attachment
                                                                File Uploaded:
                                                            </FormLabel>
                                                            <Typography>
                                                                {
                                                                    val[
                                                                        'Total Attachment File Uploaded'
                                                                    ]
                                                                }
                                                            </Typography>
                                                        </ListItem>
                                                    </Grid>
                                                )}
                                                {val.Status === 'Success' && (
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        key={'it5' + index}
                                                        sx={{
                                                            pl:
                                                                '4px !important',
                                                            pt:
                                                                '4px !important',
                                                        }}
                                                    >
                                                        <ListItem
                                                            sx={{
                                                                pt:
                                                                    '4px !important',
                                                                pb:
                                                                    '4px !important',
                                                                pl:
                                                                    '4px !important',
                                                            }}
                                                        >
                                                            <FormLabel
                                                                key={
                                                                    'read' +
                                                                    index
                                                                }
                                                                sx={{
                                                                    color:
                                                                        '#949db2',
                                                                    pr: 1,
                                                                }}
                                                            >
                                                                Total Messages
                                                                Read:
                                                            </FormLabel>
                                                            <Typography>
                                                                {
                                                                    val[
                                                                        'Total Messages Read'
                                                                    ]
                                                                }
                                                            </Typography>
                                                        </ListItem>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                ))}
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </Box>
    )
}

export default ConnectorsDetail
