import { IconButton, Input } from '@mui/material'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import React from 'react'
import { fileUpload } from 'app/services/Api'

const UploadFile = (props) => {
    const uploadFile = () => {
        const file = document.getElementById('fileData').files
        if (file.length > 0) {
            for (let index = 0; index < file.length; index++) {
                upload(file[index])
            }
        }
    }

    const upload = (file) => {
        props.setLoader(true)
        const formData = new FormData()
        formData.append('files', file)
        fileUpload(
            '/core/pipeline/' + props.pipelineData + '/document/upload',
            formData
        ).then((data) => {
            props.setLoader(false)
            if (data.length > 0) {
                props.uploadedData({
                    severity: 'success',
                    message: 'File Uploaded Successfully.',
                    open: true,
                })
            } else {
                props.uploadedData({
                    severity: 'error',
                    message: 'Uploading Failed.',
                    open: true,
                })
            }
        })
    }

    return (
        <label htmlFor="fileData">
            <input
                id="fileData"
                type="file"
                multiple
                style={{ display: 'none' }}
                onChange={() => uploadFile()}
            />
            <IconButton title="Upload" component="span">
                <CloudUploadIcon />
            </IconButton>
        </label>
    )
}

export default UploadFile
