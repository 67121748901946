const PipelineReducer = (state, event) => {
    if (event.type === 'editPipeline') {
        return { ...state, ...event.pipeline }
    } else if (event.type === 'connectors') {
        return { ...state, ...event.connectors }
    } else {
        return {
            ...state,
            [event.name]: event.value,
        }
    }
}

export default PipelineReducer
