import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import AbcOutlinedIcon from '@mui/icons-material/AbcOutlined'

const RegionData = (props) => {
    const docData = null
    return (
        <Box>
            <Grid container>
                {docData === null && (
                    <Grid
                        item
                        xs={12}
                        key={'noData'}
                        sx={{
                            textAlign: 'center',
                            pt: 'calc(455px /2 - 80px) !important',
                        }}
                    >
                        <AbcOutlinedIcon
                            sx={{ fontSize: 80, color: '#dcdcdc' }}
                        />
                        <Typography color={'#dcdcdc'}>
                            No Text Available
                        </Typography>
                    </Grid>
                )}
            </Grid>
        </Box>
    )
}

export default RegionData
