import {
    Box,
    Card,
    CardContent,
    CardHeader,
    FormLabel,
    Grid,
    LinearProgress,
    Link,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material'
import React, { useEffect, useCallback, useState } from 'react'
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined'
import { getData } from '../../services/Api'

const SummaryData = (props) => {
    const docData = props.docData
    console.log(docData)
    const [summaryData, setSummaryData] = useState(null)

    const getSummaryData = useCallback(() => {
        setSummaryData(null)
        if (docData !== null && docData.status !== 'PENDING') {
            getData(
                '/core/pipeline/' +
                    docData.pipelineId +
                    '/document/' +
                    docData.id +
                    '/summary'
            ).then((data) => {
                if (data !== null) {
                    setSummaryData(data)
                }
            })
        }
    }, [docData])

    useEffect(() => {
        getSummaryData()
    }, [getSummaryData])

    const gotoPoint = (boundingBox, pageNo) => {
        props.drawPoint(boundingBox, pageNo)
    }

    return (
        <Box sx={{ m: 1 }}>
            <Grid container>
                {(docData === null ||
                    summaryData === null ||
                    summaryData === undefined) && (
                    <Grid
                        item
                        xs={12}
                        key={'noData'}
                        sx={{
                            textAlign: 'center',
                            pt: 'calc(455px /2 - 80px) !important',
                        }}
                    >
                        <FactCheckOutlinedIcon
                            sx={{ fontSize: 80, color: '#dcdcdc' }}
                        />
                        <Typography color={'#dcdcdc'}>
                            No Summary Available
                        </Typography>
                        {docData !== null &&
                            docData.status !== 'VALIDATION_FAILED' && (
                                <Box>
                                    <Link
                                        href={
                                            '/summary?pipelineId=' +
                                            docData.pipelineId +
                                            '&docId=' +
                                            docData.id
                                        }
                                        target={'_blank'}
                                    >
                                        Open Summary
                                    </Link>
                                </Box>
                            )}
                    </Grid>
                )}
                {docData !== null && (
                    <Grid item xs={12} key={'summaryData'}>
                        {summaryData !== undefined && summaryData !== null && (
                            <Box>
                                <Box key={'keyValue'}>
                                    {summaryData.keyValuePairs.map((res, j) => (
                                        <Card
                                            variant="outlined"
                                            key={'card' + j}
                                            sx={{ mt: 1 }}
                                            onClick={() =>
                                                gotoPoint(
                                                    res.boundingBox,
                                                    res.pageNo
                                                )
                                            }
                                        >
                                            <CardHeader
                                                key={'head' + j}
                                                subheader={res.key}
                                                sx={{
                                                    p: 1,
                                                    '& .MuiCardHeader-subheader': {
                                                        color: 'darkslategray',
                                                        fontWeight: 'bold',
                                                    },
                                                }}
                                            ></CardHeader>
                                            <CardContent
                                                sx={{
                                                    p: 1,
                                                    pb: '8px !important',
                                                }}
                                                key={'content' + j}
                                            >
                                                <Grid
                                                    container
                                                    key={'container' + j}
                                                >
                                                    <Grid
                                                        item
                                                        xs={10}
                                                        key={'it2' + j}
                                                    >
                                                        {res.value}
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={2}
                                                        key={'it3' + j}
                                                    >
                                                        <Box>
                                                            <LinearProgress
                                                                key={'circular'}
                                                                variant="determinate"
                                                                value={Math.round(
                                                                    res.confidence *
                                                                        100
                                                                )}
                                                                sx={{
                                                                    backgroundColor:
                                                                        '#757575',
                                                                    height:
                                                                        '6px',
                                                                }}
                                                            ></LinearProgress>
                                                            <FormLabel>
                                                                {Math.round(
                                                                    res.confidence *
                                                                        100
                                                                )}
                                                                %
                                                            </FormLabel>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    ))}
                                </Box>
                                <Box sx={{ mt: 2 }} key={'table'}>
                                    {summaryData.tables !== null &&
                                        summaryData.tables.map((res, j) => (
                                            <Card
                                                sx={{ p: 2, mb: 1 }}
                                                key={'tableCard' + j}
                                            >
                                                <Table key={'table' + j}>
                                                    <TableHead
                                                        key={'tableHead' + j}
                                                    >
                                                        <TableRow
                                                            key={'headRow' + j}
                                                        >
                                                            {res.headers.map(
                                                                (
                                                                    tableData,
                                                                    k
                                                                ) => (
                                                                    <TableCell
                                                                        key={
                                                                            'headCell' +
                                                                            j +
                                                                            k
                                                                        }
                                                                    >
                                                                        {
                                                                            tableData
                                                                        }
                                                                    </TableCell>
                                                                )
                                                            )}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody key={'body' + j}>
                                                        {res.rows.map(
                                                            (tableRows, k) => (
                                                                <TableRow
                                                                    key={
                                                                        'bodyRow' +
                                                                        j +
                                                                        k
                                                                    }
                                                                >
                                                                    {tableRows.cells.map(
                                                                        (
                                                                            cell,
                                                                            l
                                                                        ) => (
                                                                            <TableCell
                                                                                key={
                                                                                    'bodyCell' +
                                                                                    j +
                                                                                    k +
                                                                                    l
                                                                                }
                                                                                onClick={() =>
                                                                                    gotoPoint(
                                                                                        cell.boundingBox,
                                                                                        0
                                                                                    )
                                                                                }
                                                                            >
                                                                                {
                                                                                    cell.text
                                                                                }
                                                                            </TableCell>
                                                                        )
                                                                    )}
                                                                </TableRow>
                                                            )
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </Card>
                                        ))}
                                </Box>
                            </Box>
                        )}
                    </Grid>
                )}
            </Grid>
        </Box>
    )
}

export default SummaryData
